import { getPresignedUrl, uploadFileWithProgress } from 'helpers';
import { updateLoader } from 'helpers/general';
import { toast } from 'react-toastify';

const regularFileUpload = async (file, courseId, lessonId, id, setCourseMaterial, rest) => {
  const restData = { ...rest };
  delete restData?.isLoading;
  const timeStamp = new Date().getTime();
  const fileName =
    'courses/' +
    courseId +
    '/' +
    lessonId +
    '/' + // dir structure
    timeStamp +
    '-' +
    file.name;
  const fileSize = (file.size / (1024 * 1024)).toFixed(2);
  const { url, checksum } = await getPresignedUrl(file, fileName, fileSize);
  const { data } = url;
  const preSignedUrl = data && data[0]?.url;
  if (!preSignedUrl) return Promise.reject('No preSignedUrl found');
  return uploadFileWithProgress({
    file,
    preSignedUrl,
    checksum,
    onProgress: (value) => updateLoader(value, `${id}-loading`),
    fileKey: fileName,
    callback: (url) => {
      const fullLink = `${import.meta.env.REACT_APP_CLOUDFRONT_URL}/${url}`;
      setCourseMaterial((prev) =>
        prev.map((item) =>
          item.id === id
            ? {
                ...restData,
                source: fullLink
              }
            : item
        )
      );
    },
    errorCallback: (error) => {
      toast.error('Failed to upload file');
      setCourseMaterial((prev) => prev.filter((item) => item.id !== id));
    }
  });
};

const mainFileUpload = async (file, courseId, lessonId, id, setLessons, rest) => {
  const restData = { ...rest };
  delete restData?.isLoading;
  const timeStamp = new Date().getTime();
  const fileName =
    'courses/' +
    courseId +
    '/' +
    lessonId +
    '/' + // dir structure
    timeStamp +
    '-' +
    file.name;
  const fileSize = (file.size / (1024 * 1024)).toFixed(2);
  const { url, checksum } = await getPresignedUrl(file, fileName, fileSize);
  const { data } = url;
  const preSignedUrl = data && data[0]?.url;
  if (!preSignedUrl) return Promise.reject('No preSignedUrl found');
  return uploadFileWithProgress({
    file,
    preSignedUrl,
    checksum,
    onProgress: (value) => updateLoader(value, `${id}-loading`, true),
    fileKey: fileName,
    callback: (url) => {
      const fullLink = `${import.meta.env.REACT_APP_CLOUDFRONT_URL}/${url}`;
      setLessons((prev) =>
        prev.map((item) =>
          item.id === lessonId || item.uuid === lessonId
            ? {
                ...item,
                content: {
                  ...restData,
                  source: fullLink
                }
              }
            : item
        )
      );
    },
    errorCallback: (error) => {
      // toast.error('Failed to upload file');
      // setLessons((prev) => prev.filter((item) => item.id !== id));
    }
  });
};

export const startUploading = async (filesdata, setCourseMaterial, courseId, lessonId) => {
  for (let i = 0; i < filesdata.length; i++) {
    const { file, isUrl, ...rest } = filesdata[i];
    const id = filesdata[i].id;
    const fileUploadPromise =
      !isUrl && regularFileUpload(file, courseId, lessonId, id, setCourseMaterial, rest);

    const newFile = {
      ...rest,
      lessonId,
      fileUploadPromise
    };
    setCourseMaterial((prev) => prev.map((item) => (item.id === id ? newFile : item)));
  }
};

const getDurationFromVideo = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.src = URL.createObjectURL(file);
  });
};

export const startMainLectureUploading = async (fileInput, setLessons, courseId, lessonId) => {
  const { file, isUrl, ...rest } = fileInput;
  const id = fileInput.id;
  const duration = (await getDurationFromVideo(file)) || 0;
  const fileUploadPromise =
    !isUrl && mainFileUpload(file, courseId, lessonId, id, setLessons, { ...rest, duration });
  const newFile = {
    ...rest,
    lessonId,
    duration,
    fileUploadPromise
  };
  setLessons((prev) =>
    prev.map((item) =>
      item.id === lessonId || item.uuid === lessonId
        ? {
            ...item,
            content: newFile
          }
        : item
    )
  );
};
