import React from 'react';
import { Routes, Navigate, Route, useParams } from 'react-router-dom';
import CoursePreview from 'components/organisms/CoursePreview/CoursePreview';
function CoursePreviewPage() {
  document.body.style.overflow = '';
  const { courseId } = useParams();

  if (!courseId) {
    return <Navigate to="/courses" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="quizzes" />} />
      <Route path="/:tab" element={<CoursePreview />} />
    </Routes>
  );
}

export default CoursePreviewPage;
