import { optimisticUpdater, updateCache } from 'api/utils';
import { apiSlice } from '../apiSlice';
// represent the controller
const secondaryUrl = `profile`;

export const profileExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileByUserIdAndCompanyId: builder.query({
      query: ({ userId, companyId }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}/user/${userId}`
      }),
      providesTags: ['User']
    }),
    // DON'T USE THUS QUERY TO GET OTHER USER PROFILE, use the one above
    getCurrentUserProfile: builder.query({
      query: ({ userId, companyId }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}/user/${userId}?current=true`
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // represents the current user profile
      providesTags: (result) => {
        if (result)
          return [
            { type: 'Profile', id: result?.id },
            { type: 'Current-Profile', id: 'Current' }
          ];

        return [{ type: 'Current-Profile', id: 'Current' }];
      }
    }),
    getProfilesByCompanyId: builder.query({
      query: ({ companyId, query, page = 1, size = 10, projection }) => {
        const queryParam = `page=${page}&size=${size}${query ? `&${query}` : ''}${
          projection ? `&projection=${projection}` : ''
        }`;
        return {
          url: `/v1/${secondaryUrl}/company/${companyId}?${queryParam}`
        };
      },
      providesTags: (res) => {
        const mainTags = [{ type: 'Profile', id: 'LIST' }];
        if (!res) return mainTags;
        const { result } = res;
        const tags = result.map((profile) => ({ type: 'Profile', id: profile.id }));
        return [...mainTags, ...tags];
      }
    }),
    getProfilesByKeyword: builder.query({
      query: ({ keyword, companyId, page = 1, size = 10 }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}?page=${page}&size=${size}&regex[contact.firstName]=${keyword}&regex[contact.lastName]=${keyword}`
      })
    }),
    updateProfile: builder.mutation({
      query: ({ profile, profileId }) => ({
        url: `/v1/${secondaryUrl}/${profileId}`,
        method: 'PATCH',
        body: profile
      }),
      invalidatesTags: () => [{ type: 'Profile', id: 'LIST' }]
    }),
    updateProfileRole: builder.mutation({
      query: ({ profileId, role }) => ({
        url: `/v1/${secondaryUrl}/${profileId}/role`,
        method: 'PATCH',
        body: role
      }),
      async onQueryStarted({ profileId, role }, { dispatch, queryFulfilled }) {
        const patchResult = updateCache({
          keyToUpdate: 'role',
          params: { profileId },
          queryName: 'getUserRole',
          value: role
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Role', id: 'LIST' }]));
        });
      }
    }),
    updateItEssential: builder.mutation({
      query: ({ profileId, itEssential }) => ({
        url: `/v1/${secondaryUrl}/${profileId}/itessential`,
        method: 'PATCH',
        body: itEssential
      }),
      async onQueryStarted({ profileId, itEssential }, { dispatch, queryFulfilled }) {
        const patchResult = updateCache({
          keyToUpdate: 'profile.itEssential',
          params: { profileId },
          queryName: 'getIndividualProfileByProfileId',
          value: itEssential
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Profile', id: 'LIST' }]));
        });
      }
    }),
    updateProfileContact: builder.mutation({
      query: ({ profileId, body }) => ({
        url: `/v1/${secondaryUrl}/edit/${profileId}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (res, err, { profileId }) => [
        { type: 'Profile', id: profileId },
        { type: 'Profile', id: 'LIST' }
      ]
    }),
    updateAvailability: builder.mutation({
      query: ({ profileId, availability }) => ({
        url: `/v1/${secondaryUrl}/${profileId}/availability`,
        method: 'PATCH',
        body: availability
      }),
      invalidatesTags: (result, error, { profileId }) => [{ type: 'Profile', id: profileId }]
    }),
    updateBannerImage: builder.mutation({
      query: ({ profileId, bannerImage, bannerShade }) => ({
        url: `/v1/${secondaryUrl}/${profileId}/banner-image`,
        method: 'PATCH',
        body: { bannerImage, bannerShade }
      }),
      async onQueryStarted({ profileId, bannerImage, bannerShade }, { dispatch, queryFulfilled }) {
        const patchResult = updateCache({
          keyToUpdate: 'profile.bannerImage',
          params: { profileId },
          queryName: 'getIndividualProfileByProfileId',
          value: bannerImage
        });
        const patchResult2 = updateCache({
          keyToUpdate: 'profile.bannerShade',
          params: { profileId },
          queryName: 'getIndividualProfileByProfileId',
          value: bannerShade
        });
        await optimisticUpdater([patchResult, patchResult2], queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Profile', id: 'LIST' }]));
        });
      }
    }),
    updateProfileAvailabilityStatus: builder.mutation({
      query: ({ profileId, availabilityStatus }) => ({
        url: `/v1/${secondaryUrl}/${profileId}/status`,
        method: 'PATCH',
        body: availabilityStatus
      }),
      invalidatesTags: (result, error, { profileId }) => [
        { type: 'Profile', id: profileId },
        { type: 'Profile', id: 'LIST' }
      ]
    }),

    createSharableProfilesLink: builder.mutation({
      query: ({ sharingData }) => ({
        url: `/v1/${secondaryUrl}/share`,
        method: 'POST',
        body: sharingData
      })
    }),

    getSharedProfilesBySlug: builder.query({
      query: ({ slug, passKey = '' }) => ({
        url: `/v1/${secondaryUrl}/share/${slug}${passKey ? `?passKey=${passKey}` : ''}`
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      }
    }),

    getSharedProfileBySlug: builder.query({
      query: ({ slug, passKey = '' }) => ({
        url: `/v1/${secondaryUrl}/share/profile/${slug}?passKey=${passKey}`
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      keepUnusedDataFor: 0
    }),

    getProfilePositionsByCompanyId: builder.query({
      query: ({ companyId }) => ({
        url: `/v1/${secondaryUrl}/positions/company/${companyId}`
      })
    }),
    // ================== Profile Templates Endpoints ==================
    createProfileTemplate: builder.mutation({
      query: ({ template }) => ({
        url: `/v1/${secondaryUrl}/template`,
        method: 'POST',
        body: template
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdTemplate) => {
            if (!createdTemplate) return;
            dispatch(
              apiSlice.util.updateQueryData('getProfileActiveTemplate', {}, (draft) => {
                draft.activeTemplate = createdTemplate;
              })
            );
          }
        );
      }

      // invalidatesTags: (result, error, { profileId }) => [{ type: 'Profile', id: profileId }]
    }),
    getProfileActiveTemplate: builder.query({
      query: () => ({
        url: `/v1/${secondaryUrl}/template/activeTemplate`
      }),
      providesTags: (result, error, { profileId }) => [{ type: 'Profile', id: profileId }]
    }),
    contactProfile: builder.mutation({
      query: ({ contactObject }) => ({
        url: `/v1/${secondaryUrl}/contact`,
        method: 'Post',
        body: contactObject
      })
    })
  })
});

export const {
  useGetProfileByUserIdAndCompanyIdQuery,
  useGetCurrentUserProfileQuery,
  useGetProfilesByCompanyIdQuery,
  useGetProfilesByKeywordQuery,
  useUpdateProfileMutation,
  useUpdateProfileRoleMutation,
  useUpdateItEssentialMutation,
  useUpdateAvailabilityMutation,
  useUpdateBannerImageMutation,
  useUpdateProfileContactMutation,
  useUpdateProfileAvailabilityStatusMutation,
  useCreateSharableProfilesLinkMutation,
  useGetSharedProfilesBySlugQuery,
  useGetSharedProfileBySlugQuery,
  useGetProfilePositionsByCompanyIdQuery,
  useCreateProfileTemplateMutation,
  useGetProfileActiveTemplateQuery,
  useContactProfileMutation
} = profileExtendedSlice;
