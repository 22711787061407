import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  profiles: []
};

const companyProfilesSlice = createSlice({
  name: 'company-profiles',
  initialState,
  reducers: {
    setCompanyProfiles(state, action) {
      state.profiles = action.payload || [];
    }
  }
});

export const { setCompanyProfiles } = companyProfilesSlice.actions;
export default companyProfilesSlice.reducer;

export const getProfileByCompanyIdSelector = (profileId) =>
  createSelector(
    (state) => state?.companyProfiles?.profiles || [],
    (profiles) =>
      profiles.find((profile) => profile.id === profileId || profile._id === profileId) || {
        id: profileId,
        userId: 'user' + profileId,
        role: 'Left',
        contact: {
          email: 'unavailable contact',
          firstName: 'Deleted',
          lastName: 'User',
          nickname: null
        },
        team: [],
        company: 'Unavailable',
        availability: null,
        itEssential: null,
        createdAt: 'Unavailable',
        profileRole: 'Deleted'
      }
  );
