import React from 'react';
import ProfileAccess from './routes/ProfileAccess';
import 'api';
import { ToastContainer } from 'react-toastify';
import { authenticateUser, cleanAllCookies, deleteAuthData, switchActiveProfile } from 'helpers/auth';
import { isGlobalMode, isSharedMode } from 'helpers';
import Routers from 'routes/Routers';
import { useGetCurrentUserProfileQuery } from 'api/individual-profile';
import { Loader } from 'components/ui';
import './assets/scss/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './tour.scss';

function App() {
  const [isAuthenticated, user, companyId, jwt] = authenticateUser();
  const userStatus = {
    isAuthenticated,
    user,
    token: jwt
  };
  if (!isAuthenticated) {
    cleanAllCookies();
    window.location.assign(`${import.meta.env.REACT_APP_LOGIN_URL.trim()}?_rdr=${window.location}`)
  };
  // const { data, loading } = useQuery(COMPANY_BY_COMPANYID, {
  //   variables: { accountId: companyId },
  //   skip: !companyId
  // });

  const { data, isLoading: loading } = useGetCurrentUserProfileQuery(
    {
      userId: userStatus.user._id,
      companyId
    },
    {
      skip: isSharedMode()
    }
  );

  if (loading) return <Loader fullpage={true} />;

  if ((!data || !data.company) && !loading) {
    deleteAuthData();
    return null;
  }

  switchActiveProfile(data.company._id || data.company.id, false);
  return (
    <div className="App">
      {/* if  the page is in shared mode or global mode and the user is not logged in */}
      {isSharedMode() || (isGlobalMode() && !user) ? (
        <Routers />
      ) : (
        <ProfileAccess UserStatus={userStatus} company={data.company} profileData={data} />
      )}
      <ToastContainer autoClose={3000} theme="dark" limit={5} style={{ zIndex: 99999999 }} />
      <div id='modal-root' />
      <div id='modal-root-second' />
    </div>
  );
}

export default App;
