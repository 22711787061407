import React from 'react';
import { Quizzes, Notes, QA, Resources, Participants } from './components/PagesView';

export const tabNames = {
  notes: 'Notes',
  qa: 'Q&A',
  quizzes: 'Quizzes',
  resources: 'Resources',
  participants: 'Participants'
};

const links = (courseId) => [
  {
    value: tabNames.quizzes,
    path: `/courses/preview/${courseId}/quizzes`,
    page: <Quizzes />
  },
  {
    value: tabNames.notes,
    path: `/courses/preview/${courseId}/notes`,
    page: <Notes />
  },
  {
    value: tabNames.resources,
    path: `/courses/preview/${courseId}/resources`,
    page: <Resources />
  },
  {
    value: tabNames.qa,
    path: `/courses/preview/${courseId}/qa`,
    page: <QA />
  },
  {
    value: tabNames.participants,
    path: `/courses/preview/${courseId}/participants`,
    page: <Participants />
  },
];

export default links;
