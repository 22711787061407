import BannerProfile from 'components/molecules/BannerProfile/BannerProfile';
import React, { Suspense, useState, useEffect} from 'react';
import styles from './Profile.module.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { Button, Loader } from 'components/ui';
// import ProfilePic from 'components/ui';
import { getS3Url } from 'helpers/s3Handler';
import Edit from '../../../assets/icons/edit.svg?react';
import { useDispatch, useSelector } from 'react-redux';
import defaultImageProfile from '../../../assets/images/avatar.webp';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from './hooks/useGetSharedProfile';
import { ProfileInfo } from './components';
import Arrow from 'assets/icons/arrowright.svg?react';
import { useGetCompanyProfileQuery } from 'api/company-profile';
import {
  getCurrentCompanyIdSelector,
  setTouring,
  getIsUserTouringSelector
} from 'store/login.slice';
import { COMPANY_INITIAL_SETTINGS } from 'constants';
import { ProfilePic } from 'components/ui';
import { Link } from 'react-router-dom';
import Joyride from 'react-joyride';
import { useTour } from '@reactour/tour';
import { TemplateLayout } from './templates';
import { useTranslation } from 'react-i18next';

export default function ProfileView({ currentProfile }) {
  const [sharedDate, navigateWithSharedMode, goBack] = useGetSharedProfile();
  const { currentStep, setCurrentStep } = useTour();
  const isTouring = useSelector(getIsUserTouringSelector);
  const { t } = useTranslation();

  const steps = [
    {
      content: <h2>{t('welcome-to-wizrx-profile-page')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'center',
      target: 'body'
    },
    {
      content: <h2>{t('this-page-is-made-up-of-multiple-sections')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'center',
      target: 'body'
    },
    {
      content: <h2>{t('here-you-can-find-the-name')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'top-start',
      target: '.joyrideLeftPart'
    },
    {
      content: <h2>{t('you-can-use-the-options')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyrideTabs'
    },
    {
      content: <h2>{t('the-home-section-contains')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyridehome'
    },
    {
      content: <h2>{t('the-my-role-section')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyriderole'
    },
    {
      content: <h2>{t('the-awards-section')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyrideawards'
    },
    {
      content: <h2>{t('the-reviews-section')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyridereviews'
    },
    {
      content: <h2>{t('the-availability-section')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyrideavailability'
    },
    {
      content: <h2>{t('finally-the-it-essentials-home')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyrideitEssentials'
    }
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const profileId = useParams()?.id || currentProfile?.id;
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const isCurrentUserProfile = currentProfile?.id && profileId === currentProfile?.id;
  const profileData = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: isSharedMode()
    }
  );
  const { data: companyData } = useGetCompanyProfileQuery({ companyId }, { skip: isSharedMode() });
  const {
    data: resultData,
    isLoading,
    isError,
    isSuccess,
    error
  } = !isSharedMode() ? profileData : sharedDate;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  let content;
  if (isLoading) content = <Loader fullpage />;
  else if (isError) {
    content = (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  } else if (isSuccess) {
    const userId = resultData.userId;
    const companyVisibleTabs =
      companyData?.settings?.visibleProfileSegments ||
      COMPANY_INITIAL_SETTINGS.visibleProfileSegments;

    const targetProfile = isSharedMode() ? resultData?.sharedProfile : resultData?.profile;
    content = (
      <Suspense fallback={<Loader fullpage={true} />}>
        <div className={styles.profileWrapper}>
          <BannerProfile
            bannerShade={targetProfile?.bannerShade}
            edit={isCurrentUserProfile}
            image={targetProfile?.bannerImage}
            userId={userId}
            isCurrentUserProfile={isCurrentUserProfile}
            profileId={profileId}
            profile={targetProfile}
          />
          <div className={isMobile ? `${styles.mobile} ${styles.container}` : styles.container}>
            <div className={styles.profile}>
              <div className={styles.editProfile}>
                <ProfilePic
                  extraClass="profileMain"
                  width="200"
                  height="200"
                  image={
                    targetProfile?.picture ? getS3Url(targetProfile?.picture) : defaultImageProfile
                  }
                />
                {isCurrentUserProfile && (
                  <Link to={`/edit/${profileId}`} className={styles.editicon}>
                    <Edit onClick={() => setCurrentStep(currentStep + 1)} />
                  </Link>
                )}
              </div>
              <div className={styles.container}>
                <div className={`${styles.leftPart} joyrideLeftPart`}>
                  <ProfileInfo isCurrentUserProfile={isCurrentUserProfile} profileId={profileId} />
                </div>
                <div className={styles.rightPart}>
                  <TemplateLayout userId={userId} companyVisibleTabs={companyVisibleTabs} />
                </div>
              </div>
              <div className={styles.goBack}>
                <Button
                  bgColor="transparent"
                  onClick={() => {
                    if (isSharedMode()) return goBack();
                    navigate('/profiles');
                  }}
                >
                  <Arrow />
                  {t('Go Back')}{' '}
                </Button>
              </div>
            </div>
          </div>
          {/* <Joyride
            callback={(data) => {
              if (data?.action == 'reset') {
                dispatch(setTouring(false));
              }
            }}
            continuous
            hideCloseButton
            run={isTouring}
            scrollToFirstStep
            scrollOffset={400} //Size of largest modal
            showProgress
            showSkipButton
            steps={steps}
            styles={{
              options: {
                arrowColor: 'ffffff',
                backgroundColor: 'rgb(13, 13, 13)',
                overlayColor: 'rgb(13, 13, 13,0.5)',
                primaryColor: 'rgb(11, 137, 110)',
                textColor: 'rgb(207, 207, 207)',
                zIndex: 1000
              }
            }}
          /> */}
        </div>
      </Suspense>
    );
  }
  return content;
}
