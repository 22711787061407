import { Link } from 'react-router-dom';
import styles from './CourseCard.module.scss';
import Dots from 'assets/icons/dots.svg?react';
import Edit from 'assets/icons/editicon.svg?react';
import Delete from 'assets/icons/delete.svg?react';
import { useSelector } from 'react-redux';
import { getProfileByCompanyIdSelector } from 'store/companyprofiles.slice';

export default function CourseCard({ interactions, thumbnail, title, extraClass, actions, clickEvent, createdBy, createdAt, reviews, ...rest }) {
  const { chapters, lessons } = rest;
  const chaptersCount = chapters?.length || 0;
  const lessonsCount = lessons?.length || 0;

  const courseOwnerData = useSelector(getProfileByCompanyIdSelector(createdBy))
  const { contact } = courseOwnerData || {};
  const { firstName, lastName } = contact || {};
  const ownerName = firstName || lastName ? `${firstName} ${lastName}` : 'Unknown';

  const time = new Date(createdAt);
  const formattedTime = time.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const rating = reviews?.reduce((acc, curr) => acc + curr.rating, 0) / reviews?.length || 0;
  return (
    <div className={`${styles.card} ${styles[extraClass]}`}>
      {interactions && (
        <div className={styles.action_overlay}>
          {
            !!(interactions.edit) && (
              <button onClick={interactions.edit} className={styles.action_btn}>
                <Edit />
              </button>
            )
          }
          {
            !!(interactions.delete) && (
              <button onClick={interactions.delete} className={styles.action_btn}>
                <Delete />
              </button>
            )
          }
        </div>
      )}
      <img className={styles.image} src={thumbnail} alt="course" />
      <div className={styles.content}>
        <div className={styles.stars}>
          {[...Array(5)].map((_, i) => (
            <span
              key={i}
              className={i < Math.floor(rating) ? styles.starFilled : styles.starEmpty}
            >
              ★
            </span>
          ))}
        </div>
        <span className={styles.subtitle}>{chaptersCount + ' Chapters' + ' | ' + lessonsCount + ' Lessons'}</span>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.btn}>
          <span className={styles.subtitle}>{formattedTime} by <strong>{ownerName}</strong></span>
          <Link to={rest?.id && '/courses/preview/' + rest?.id + '/quizzes'} className={styles.button}>View course</Link>
        </div>
      </div>
    </div>
  );
}
