import DashboardView from 'components/organisms/UpDashboard/DashboardViewNew';
import React from 'react';

function UpDashboard() {

  return (
    <DashboardView />
  );
}

export default UpDashboard;
