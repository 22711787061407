import { Link } from 'react-router-dom';
import styles from './ResourceCard.module.scss';
import Dots from 'assets/icons/dots.svg?react';
import Edit from 'assets/icons/editicon.svg?react';
import Delete from 'assets/icons/delete.svg?react';

export default function ResourceCard({ resource }) {

  const { title, mimeType, extension, size, source } = resource

  const actualType = mimeType?.split('/')[0];
  const actualExtension = extension?.split('.')[1] || extension;
  const sizeInMB = size / (1024 * 1024);

  const downloadResource = () => {
    if (!source) return;
    const link = document.createElement('a');
    link.href = source;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    // don't open the link in the same tab
    link.setAttribute('target', '_blank');
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={`${styles.card}`}>
      <div className={styles.head_info}>
        <span className={styles.title}>{resource?.title}</span>
      </div>
      <span className={styles.regular_info_span}>{actualType}</span>
      <span className={styles.regular_info_span}>{sizeInMB.toFixed(2)} MB</span>
      <span className={styles.regular_info_span}>{actualExtension}</span>
      <div className={styles.dl_container}>
        <button className={styles.dl_btn} onClick={downloadResource}>
          Download
        </button>
      </div>
    </div>
  );
}
