import React, { useLayoutEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import links from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import NavTabs from 'components/atoms/NavTabs/NavTabs';
import MediaList from 'components/molecules/MediaList/MediaList';
import Back from 'assets/icons/back.svg?react';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import Share from 'assets/icons/share_svg.svg?react';
import Favourite from 'assets/icons/fav.svg?react';
import { Button } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneCourse } from 'store/course.slice';
import { getCurrentUserProfileId } from 'store/login.slice';
import { Forms, Notes, QA, Reviews, Resources } from './components/PagesView';


export default function Course() {
  const { courseId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { courses } = useSelector((state) => state.courses);
  const courseData = courses.find((course) => course.id === courseId);
  const fullyLoaded = courseData?.chapters?.lessons;
  const [isOpen, setIsOpen] = useState(true);
  const [currentMedia, setCurrentMediaData] = useState({
    chapterId: null,
    lessonId: null,
    lessonUUID: null,
  });

  const { title, banner } = courseData || {};
  const profileId = useSelector(getCurrentUserProfileId);
  useLayoutEffect(() => {
    dispatch(fetchOneCourse({ id: courseId }));
  }, []);

  const toggleContainer = () => setIsOpen(!isOpen);

  if (!courseData && !fullyLoaded) {
    // could be smth else
    return null;
  }

  const personalProgress = courseData?.progress.filter((el) => el.studentId === profileId);
  const allCompletedLessons = [].concat(...personalProgress.map((el) => el.completedLessons));

  const reviews = courseData?.reviews || [];

  const links = [
    {
      value: 'Forms',
      path: `/course/${courseId}/forms`,
      link: 'forms',
      page: <Forms />
    },
    {
      value: 'Notes',
      path: `/course/${courseId}/notes`,
      link: 'notes',
      page: <Notes />
    },
    {
      value: 'Resources',
      path: `/course/${courseId}/resources`,
      link: 'resources',
      page: <Resources currentMediaIds={currentMedia} course={courseData} />
    },
    {
      value: 'QA',
      path: `/course/${courseId}/qa`,
      link: 'qa',
      page: <QA />
    },
    {
      value: 'Reviews',
      path: `/course/${courseId}/reviews`,
      link: 'reviews',
      page: <Reviews reviews={reviews} profileId={profileId} courseId={courseId} />
    },
  ];

  return (
    <div className={styles.course_view_container}>
      <div className={styles.courseBanner} style={{
        background: 'url(' + banner + ') no-repeat center center',
      }}></div>
      <div className={styles.mainContainer}>
        <button className={styles.backButton}>
          <Back onClick={() => navigate('/courses')} /> {title}
        </button>
        <MediaList course={courseData} allCompletedLessons={allCompletedLessons} onLessonChange={setCurrentMediaData} />
        <div className={styles.topContainer}>
          <NavTabs links={links} extraClass="courseTabs" />
          <div className={styles.buttons}>
            <Button color="#E1E1E1" bgColor="#333">
              <Favourite />
              Favourite
            </Button>
            <Arrow
              onClick={toggleContainer}
              className={`${styles.arrow} ${isOpen ? styles.arrowOpen : ''}`}
            />
          </div>
        </div>
        {
          isOpen && (
            <div className={`${styles.container} ${isOpen ? styles.open : styles.closed}`}>
              <main className={styles.view_container}>
                <Routes>
                  {
                    links.map((datalink) => {
                      return <Route exact={true} key={datalink.value} path={datalink.link} element={datalink.page} />
                    })
                  }
                  <Route path='*' element={<Navigate to="forms" />} />
                </Routes></main>
            </div>
          )
        }
      </div>
    </div>
  );
}
