import React, { useState } from 'react';
import RatingStarsHoverable from 'components/molecules/RatingStarsHoverable/RatingStarsHoverable';
import styles from '../../../styles.module.scss';
import style from './styles.module.scss';
import { Button } from 'components/ui';
import { useDispatch } from 'react-redux';
import { addReviewToCourse } from 'store/course.slice';
import ReviewCard from './ReviewCard';

export default function index({ reviews, profileId, courseId}) {
  const [rating, setRating] = useState(0);
  const [userInput, setUserInput] = useState('');
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSaveReview = () => {
    if (userInput.trim()) {
      const newReview = {
        description: userInput,
        rating: rating,
      };
      dispatch(addReviewToCourse({
        courseId,
        review: newReview,
      }));
      setUserInput('');
      setRating(0);
    }
  };

  return (
    <div className={style.container}>
      <h2 className={styles.sectionTitle}>Reviews ({reviews.length})</h2>
      <div className={style.reviewForm}>
        <h3 className={style.title}>Leave a review</h3>
        <RatingStarsHoverable size={2} value={rating} setValue={setRating} />
        <textarea
          placeholder="Share your experience"
          onChange={handleInputChange}
          value={userInput}
        ></textarea>
        <Button
          style={{
            cursor: !userInput.trim() || rating === 0 ? 'not-allowed' : 'pointer'
          }}
          bgColor={
            userInput.trim() && rating > 0
              ? 'linear-gradient(45deg, #30a5e0, #00a480)'
              : 'rgba(55, 54, 54, 0.53)'
          }
          onClick={handleSaveReview}
          disabled={!userInput.trim() || rating === 0}
        >
          Submit a review
        </Button>
      </div>
      <div className={style.reviewsList}>
        {reviews.map((review) => (
          <ReviewCard key={review.id} review={review} profileId={profileId} />
        ))}
      </div>
    </div>
  );
}
