import React, { useState, Suspense, useEffect } from 'react';
import styles from './MediaList.module.scss';
import MediaItem from 'components/atoms/MediaItem/MediaItem';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import { BiSolidHide } from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import { Loader } from 'components/ui';
import VideoPlayer from './Player';
import { useDispatch } from 'react-redux';
import { toggleLessonProgress } from 'store/course.slice';

export default function MediaList({ course, allCompletedLessons, onLessonChange }) {
  const dispatch = useDispatch();
  const chapters = course?.chapters || [];
  const firstChapter = chapters?.length ? chapters[0] : {};
  const initialMediaItem = (firstChapter?.length && typeof firstChapter !== 'string') ? firstChapter?.lessons[0] : {};
  const initContent = initialMediaItem?.content || {};
  const [activeMedia, setActiveMedia] = useState( initContent && {
    type: initContent?.mimeType,
    src: initContent?.source,
    poster: initContent?.poster,
    chapterId: firstChapter?.id,
    index: initialMediaItem?.id,
    duration: initContent?.duration,
    size: initContent?.size,
    lastModified: initContent?.lastModified,
    uuid: initialMediaItem?.uuid,
    extension: initContent?.extension
  });

  useEffect(() => {
    if (activeMedia?.index) {
      onLessonChange({
        chapterId: activeMedia.chapterId,
        lessonId: activeMedia.index,
        uuid: activeMedia.uuid
      });
    }
  }, [activeMedia]);

  const [openChapters, setOpenChapters] = useState(chapters.map((_, index) => index === 0));
  const [isListVisible, setIsListVisible] = useState(true);

  const handleCheckChange = async (chapterId, mediaId, callback) => {
    const courseId = course.id;
    dispatch(toggleLessonProgress({ courseId, chapterId, lessonId: mediaId, callback }));
  };

  const handleItemClick = (chapterId, mediaId, mediaItem, uuid) => {
    const { content } = mediaItem;
    const { source, size, mimeType, lastModified, extension, duration, poster } = content;
    setActiveMedia({
      type: mimeType,
      src: source,
      poster,
      chapterId,
      index: mediaId,
      duration,
      size,
      lastModified,
      uuid,
      extension
    });
  };

  const toggleSection = (index) => {
    setOpenChapters((prevState) => prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };

  return (
    <div className={`${styles.container} ${!isListVisible ? styles.hidden : ''}`}>
      {activeMedia?.type?.includes('video') ? (
        <VideoPlayer activeMedia={activeMedia} />
      ) : (
        <div>
          <Suspense fallback={<Loader section />}>
            <iframe src={activeMedia.src} className={styles.pdfViewer} title="PDF Viewer" />
          </Suspense>
        </div>
      )}

      <div className={styles.controls}>
        <button onClick={toggleListVisibility}>
          {isListVisible ? (
            <div>
              <p>Hide Content</p>
              <BiSolidHide />
            </div>
          ) : (
            <div>
              <p>Show Content</p>
              <BiShow />
            </div>
          )}
        </button>
      </div>

      {isListVisible && (
        <div className={styles.list}>
          {chapters?.map((chapter, chapterIdx) => {
            const { title, lessons } = chapter;
            const chapterId = chapter.id;
            let totalLessons = lessons?.length || 0;
            let totalMinutes = 20;
            return (
              <div key={chapterId}>
                <div
                  className={`${styles.titleContainer} ${openChapters[chapterIdx] ? styles.active : ''}`}
                  onClick={() => toggleSection(chapterIdx)}
                  aria-hidden="true"
                >
                  <div className={styles.topWrapper}>
                    <h2 className={styles.mainTitle}>
                      Chapter {chapterIdx + 1}: {title}
                    </h2>
                    <p className={styles.contain}>
                      {totalLessons} {totalLessons === 1 ? 'lesson' : 'lessons'} | {totalMinutes}{' '}
                      min
                    </p>
                  </div>
                  <Arrow
                    className={openChapters[chapterIdx] ? styles.arrowOpen : styles.arrowClosed}
                  />
                </div>
                <div
                  className={`${styles.lessons} ${openChapters[chapterIdx] ? styles.open : ''}`}
                >
                  {lessons?.map((lesson) => {
                    const { id, uuid } = lesson;
                    const itemId = `${id}`;
                    return (
                      <div
                        key={id}
                        aria-hidden="true"
                        onClick={() => handleItemClick(chapterId, id, lesson, uuid)}
                      >
                        <MediaItem
                          active={
                            activeMedia.chapterId === chapterId &&
                            activeMedia.index === id
                          }
                          {...lesson}
                          isChecked={allCompletedLessons?.includes(id) || false}
                          onCheckChange={handleCheckChange}
                          chapterId={chapterId}
                          id={id}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
