import React, { useLayoutEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import links, { tabNames } from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import NavTabs from 'components/atoms/NavTabs/NavTabs';
import CourseBanner from 'components/atoms/CourseBanner/CourseBanner';
import AboutCourse from 'components/molecules/AboutCourse/AboutCourse';
import CourseReviews from 'components/molecules/CourseReviews/CourseReviews';
import CourseStructure from 'components/molecules/CourseStructure/CourseStructure';
import InstructorSection from 'components/molecules/InstructorSection/InstructorSection';
import { getCourse } from 'api/courses/coursesApis';
import { Button } from 'components/ui';
import { fetchOneCourse } from 'store/course.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
import { getProfileByCompanyIdSelector } from 'store/companyprofiles.slice';

export default function CoursePreview() {
  const { tab, courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const advancedTab = tab?.toLowerCase();
  const validTab = links(courseId)?.find((link) => link.path?.includes(tab));
  const { courses } = useSelector((state) => state.courses);
  const profileId = useSelector(getCurrentUserProfileId);
  const mainCourse = courses?.find((course) => course.id === courseId);
  const personalProgress = mainCourse?.progress?.filter((el) => el?.studentId === profileId);
  const allCompletedLessons = [].concat(personalProgress?.map((el) => el?.completedLessons));
  const overAllProgress = Math.floor((allCompletedLessons?.length / mainCourse?.lessons?.length) * 100) || 0;

  useLayoutEffect(() => {
    dispatch(fetchOneCourse({ id: courseId }));
  }, []);


  useLayoutEffect(() => {
    if (!tab || !validTab) {
      navigate('/courses');
    }
  }, [tab, navigate, validTab]);

  const { skills, thumbnail, banner, title, subtitle, description, chapters, reviews, createdBy } = mainCourse || {};

  const courseOwnerData = useSelector(getProfileByCompanyIdSelector(createdBy))

  const navigateToCourse = () => {
    navigate('/course/' + courseId + '/forms', {
      state: { course: mainCourse }
    });
  }


  return (
    <>
      <CourseBanner
        image={banner || "https://cdn.wizrx.wizrapps.com/Rectangle+75.jpg"}
        title={title || "Untitled"}
        subtitle={subtitle || "Subtitle..."}
        description={description || "Description..."}
        progress={overAllProgress}
        ownerData={courseOwnerData}
      />
      <ResponsiveWrapper>
        <AboutCourse
          thumbnail={thumbnail}
          skills={skills}
          description="In this Mental Health course you will learn what the responsibilities are from both employers and employees in relation to safety, equality and diversity, discrimination, bullying and harassment and conflict."
        />
        <CourseStructure editable={false} navigateToCourse={navigateToCourse} chapters={chapters || []} />
        {/* <InstructorSection />
        // don't have these yet
        */}
        {
          reviews?.length && (
            <CourseReviews reviews={reviews} />
          )
        }
        {/* <div className={styles.topContainer}>
          <NavTabs disableScroll={true} links={links((courseId))} extraClass="instructorTabs" />
          <div className={styles.buttons}>
            {advancedTab === tabNames.resources.toLowerCase() && <Button>Upload Resources</Button>}
            {advancedTab === tabNames.quizzes.toLowerCase() && (
              <Button>
                <span>+</span> Create quiz
              </Button>
            )}
          </div>
        </div>
        <div className={styles.container}>
          <main className={styles.view_container}>{validTab?.page || links(courseId)[0].page}</main>
        </div> */}
      </ResponsiveWrapper>
    </>
  );
}
