import React, { useState, useEffect } from 'react';
import styles from './CourseDropdown.module.scss';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import File from 'assets/icons/document.svg?react';
import Play from 'assets/icons/video.svg?react';
import Close from 'assets/icons/closeIcon.svg?react';

const Dropdown = ({ title, lessons, isOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top} aria-hidden="true" onClick={toggleDropdown}>
          <div className={styles.title}>
            <Arrow className={isDropdownOpen ? styles.opened : styles.closed} />
            <h2>{title}</h2>
          </div>
          <div className={styles.right}>
            <p>
              {(lessons?.length || 0)} {(lessons?.length || 0) > 1 ? 'Lessons' : 'Lesson'}
            </p>
          </div>
        </div>
        <div className={`${styles.content} ${isDropdownOpen ? styles.open : styles.closed}`}>
          <div className={styles.data}>
            {lessons?.map((item, i) => (
              <div key={i} className={styles.item}>
                <div className={styles.left}>
                  <h3>{item?.title}</h3>
                </div>
                <div className={styles.actions}>
                  <p>{item?.duration || '4:20'} min</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
