import { Input } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import styles from './ShareProfileModal.module.scss';
import { useTranslation } from 'react-i18next';

const resetData = {
  passKey: '',
  expireAt: new Date(new Date().setDate(new Date().getDate() + 1))
};
const ShareProfileModal = ({ openShareModal, onClose, onSubmit, isLoading }) => {
  const [data, setData] = useState({ ...resetData });
  const { t } = useTranslation();

  if (!openShareModal) return null;
  return (
    <CreateModal
      textSave={t("generate-link")}
      isSubmitting={isLoading}
      handleClose={onClose}
      clickEvent={() => {
        const dataDto = {
          ...data,
          passKey: data.passKey.trim()
        };
        onSubmit(dataDto);
        setData({ ...resetData });
      }}
    >
      <div className="share-wrapper">
        <h1>{t("share-profile")}</h1>
        <Input
          label={t("pass-key")}
          type="text"
          placeholder={t("if-no-pass-key")}
          extraClass="teamInput"
          value={data.passKey}
          name="passKey"
          autoFocus
          onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
        />
        <div className={styles['datePickerContainer']}>
          <p> {t("expires-at")} </p>
          <ReactDatePicker
            className={styles['datePicker']}
            // maxDate={new Date()}
            selected={data.expireAt}
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            onChange={(date) => {
              setData({ ...data, expireAt: date });
            }}
          />
        </div>
      </div>
    </CreateModal>
  );
};

export default ShareProfileModal;
