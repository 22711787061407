import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UniversalMenu } from '@learnio-as/universal-menu';
import { deleteAuthData } from 'helpers/auth';
import { getHiddenUMIcons } from 'helpers/general';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { updateExpandState } from 'store/menu.slice';
import Cookies from 'helpers/cookies';
import { LIBRARY_URL } from 'utils/constans';
const initialValues = {
  CURRENT_ORG: 'engineering',
  mode: 'dark',
  displayValue: '-100',
  accounts: [
    'Intranet',
    'Sales',
    'HR',
    'Training',
    'Community',
    'Manuals',
    'Management',
    'Marketing',
    'Investors'
  ]
};

export default function UniversalMenuComponent({
  showMenu,
  handleShowMenu,
  currentUserId,
  currentUserProfile
}) {
  const [values, setProps] = useState(initialValues);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const WIZRX_URL = window.location.origin;
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const cookies = new Cookies();

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      displayValue: !showMenu ? '100' : '0'
    }));
  }, [showMenu]);

  const toggleMenu = () => {
    handleShowMenu();
  };
  const logout = () => {
    deleteAuthData();
  };

  const HUB_URL = `${import.meta.env.REACT_APP_LOGIN_URL}/hub`;

  const onExpand = (value) => {
    dispatch(updateExpandState(value));
  };

  const handleResize = () => {
    if (window.innerWidth > 1270) {
      showMenu && dispatch(toggleMenu());
    } else {
      !showMenu && dispatch(toggleMenu());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showMenu]);

  //TODO: Add the correct accountRole role
  return (
    <UniversalMenu
      {...{
        ...values,
        logout,
        WIZRX_URL,
        ENV: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
        toggleMenu,
        _redirect: navigate,
        accountRole: '',
        CURRENT_ORG: `${companyId}-${cookies.get('activeProfile')?.split('-')[1]}`,
        CURRENT_PORTAL: `${cookies.get('activeProfile').split('-')[1]}` || '',
        activeProfile: cookies.get('activeProfile'),
        redirectURL: 'wizrx',
        profile: { ...currentUserProfile },
        onExpand,
        LIBRARY_URL,
      }}
    />
  );
}
UniversalMenuComponent.propTypes = {
  showMenu: PropTypes.bool,
  handleShowMenu: PropTypes.func
};
