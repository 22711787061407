import React, { useState, useEffect } from 'react';
import Dropdown from '../CourseDropdown/CourseDropdown';
import styles from './CourseStructure.module.scss';
import { Button } from 'components/ui';

export default function CourseStructure({ chapters, navigateToCourse }) {

  const [expandAll, setExpandAll] = useState(false);

  const toggleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div>
          <h2>Course structure</h2>
          <p>
            {chapters.length} Sections |{' '}
            {chapters.reduce(
              (totalLessons, chapter) => totalLessons + (chapter?.lessons?.length || 0),
              0
            )}{' '}
            Lessons
          </p>
        </div>
        <button className={styles.expand} onClick={toggleExpandAll}>
          {expandAll ? 'Collapse all sections' : 'Expand all sections'}
        </button>
      </div>
      <div className={styles.data}>
        {chapters.map((chapter, index) => (
          <Dropdown
            key={chapter?.id || index}
            title={chapter?.title}
            lessons={chapter?.lessons}
            isOpen={expandAll}
          />
        ))}
      </div>
      <div className={styles.startBtn}>
        <Button onClick={navigateToCourse} bgColor="linear-gradient(45deg, #30a5e0, #00a480)">Start Course</Button>
      </div>
    </div>
  );
}
