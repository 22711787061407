import React, { useEffect, useState } from 'react'
import style from './styles.module.scss'
import profile from 'assets/images/portalofmonth.webp';
import RatingStarsHoverable from 'components/molecules/RatingStarsHoverable/RatingStarsHoverable';
import { useDispatch, useSelector } from 'react-redux';
import { editCourseReview, rmcourseReview } from 'store/course.slice';
import { togglePopup } from 'store/popups/popupSlice';
import { getProfileByCompanyIdSelector } from 'store/companyprofiles.slice';

export default function ReviewCard({ review, profileId }) {

  const studentId = review.studentId;
  const editable = profileId === studentId;
  const editedBefore = review.edited;
  const [isEditing, setIsEditing] = useState(false);
  const [_review, setReview] = useState(review);

  const reviewerData = useSelector(getProfileByCompanyIdSelector(studentId))
  const { contact } = reviewerData || {};
  const { firstName, lastName } = contact || {};
  const reviwerName = firstName || lastName ? `${firstName} ${lastName}` : 'Unknown';

  const dispatch = useDispatch();

  useEffect(() => {
    setReview(review);
  }, [review]);

  const mongoTimeConverter = (time) => {
    try {
      const date = new Date(time);
      return date.toLocaleDateString();
    } catch (error) {
      return time || '';
    }
  }

  const tempRevChange = (payload) => {
    setReview({
      ..._review,
      ...payload
    });
  }

  const changeDescription = (e) => {
    tempRevChange({
      description: e.target.value
    });
  }

  const saveNewRev = () => {
    setIsEditing(false);
    if (!_review.description.trim()) return;
    if (!_review.rating) return;
    if (_review.description === review.description && _review.rating === review.rating) return;
    dispatch(
      editCourseReview({
        review: _review
      })
    );
  };

  const deleteRevPopupToggle = () => {
    dispatch(togglePopup({
      pointer: 399,
      data: {
        validAction: () => {
          dispatch(rmcourseReview({
            reviewId: _review.id || _review._id,
            callback: () => dispatch(togglePopup({
              pointer: 0,
            }))
          }));
        },
        desc: 'Are you sure you want to delete this Review?',
        validButtonText: 'Delete',
      }
    }))
  };



  return (
    <div className={style.reviewCard} key={_review.id}>
      <div className={style.topContainer}>
        <div className={style.info}>
          <img src={profile} alt="profile" />
          <div className={style.nameHolder}>
            <p>{reviwerName} {editedBefore && <span className={style.edited_span}> (edited)</span>}</p> 
            <span className={style.date}>
              Reviewed on {mongoTimeConverter(_review.updatedAt)}
            </span>
          </div>
        </div>
        <RatingStarsHoverable size={1.5} value={_review.rating} setValue={(val) => tempRevChange({
          rating: val
        })} disabled havorable={isEditing} />
      </div>
      <div className={style.review}>
        <textarea className={style.description} value={_review.description} onChange={changeDescription} disabled={!isEditing} />
        {
          editable && (
            <div className={style.topContainer_interaction}>
              <button className={style.edit_delete_bn} onClick={() => {
                setIsEditing(!isEditing);
                setReview(review);
              }}>{isEditing ? 'Cancel' : 'Edit'}</button>
              <button
                className={style.edit_delete_bn}
                onClick={() => isEditing ? saveNewRev() : deleteRevPopupToggle()}
              >
                {isEditing ? 'Save' : 'Delete'}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}
