import React from 'react';
import { HiOutlineDotsVertical } from "react-icons/hi";
import styles from './CardComponent.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProfileByCompanyIdSelector } from 'store/companyprofiles.slice';

const CardComponent = ({
  title,
  author,
  completionPercentage,
  completed,
  chapters,
  lessons,
  thumbnail,
  createdBy,
  id,
  _id
}) => {

    const courseOwnerData = useSelector(getProfileByCompanyIdSelector(createdBy))
    const { contact } = courseOwnerData || {};
    const { firstName, lastName } = contact || {};
    const ownerName = firstName || lastName ? `${firstName} ${lastName}` : 'Unknown';
  

  const isInProgress = !completed && completionPercentage >= 0;
  const buttonText = completed
    ? 'Download certificate'
    : completionPercentage === 0
      ? 'View Course'
      : 'Continue learning';

  const progressContent = completed ? (
    <div>
      <div className={styles.completedBar}></div>
      <div className={styles.progressTextContainer}>
        <span className={styles.progressText}>100% completed</span>
      </div>
    </div>
  ) : isInProgress ? (
    <div>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{ width: `${completionPercentage}%` }}
        ></div>
      </div>
      <div className={styles.progressTextContainer}>
        <span className={styles.progressText}>{completionPercentage}%</span>
      </div>
    </div>
  ) : null;

  const getCertificate = () => {
    // do nothing for now
  };

  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img
          src={thumbnail}
          alt="Course thumbnail"
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <HiOutlineDotsVertical className={styles.icon} />
        <p className={styles.info}>
          {chapters?.length || 0} chapters | {lessons?.length || 0} lessons
        </p>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.author}>{ownerName}</p>

        <div className={styles.progressContainer}>
          {progressContent}
          <div className={styles.buttonContainer}>
            {
              completed ? (
                <button className={styles.button} onClick={getCertificate}>{buttonText}</button>
              ) : (
                <Link className={styles.button} to={`/course/${id || _id}/forms`}>{buttonText}</Link>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
