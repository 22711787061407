import { store } from '../store';
import { apiSlice } from 'api/apiSlice';
import CryptoJS from 'crypto-js';

const checkForRestrictions = (file) => {
  const MBSize = file.byteLength / (1024 * 1024);
  return MBSize <= 100;
};

export const uploadFileWithProgress = ({
  file,
  preSignedUrl,
  checksum,
  onProgress,
  fileKey,
  isURL = false,
  callback,
  errorCallback,
  cancelCallback
}) => {
  let xhr;
  const promise = new Promise((resolve, reject) => {
    xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', file?.type);
    xhr.setRequestHeader('Content-MD5', checksum);
    onProgress &&
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percentComplete = event.loaded / event.total;
          onProgress({ loaded: event.loaded, total: event.total, percent: percentComplete });
        }
      });
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(isURL ? xhr.responseURL.split('?')[0] : fileKey);
        } else {
          errorCallback && errorCallback();
          reject('File upload failed lmfao' + xhr);
        }
      }
    };
    xhr.onerror = (e) => {
      reject('Network error or CORS issue during upload');
      console.error('new error lmfao', e);
      errorCallback && errorCallback();
    };
    xhr.send(file);
  });
  promise.cancel = () => {
    if (xhr) {
      xhr.abort();
      cancelCallback && cancelCallback();
    }
  };
  promise.then(callback);
  return promise;
};

export const getPresignedUrl = async (file, fileKey, fileSize) => {
  // Compute the MD5 checksum of the file
  const fileReader = new FileReader();

  const md5Promise = new Promise((resolve, reject) => {
    fileReader.onload = () => {
      const wordArray = CryptoJS.lib.WordArray.create(fileReader.result);
      const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
      resolve(md5Hash);
    };

    fileReader.onerror = () => {
      reject(new Error('Error reading file for MD5 checksum'));
    };

    fileReader.readAsArrayBuffer(file);
  });

  const md5Checksum = await md5Promise;
  const body = {
    keys: [{ key: fileKey, size: fileSize }],
    operation: 'PUT',
    contentTypes: [file?.type],
    ContentMD5: md5Checksum
  };

  const url = await store.dispatch(
    apiSlice.endpoints.createPreSignedUrl.initiate({
      body
    })
  );
  return {
    url,
    checksum: md5Checksum
  };
};

export const addBlobToS3Bucket = async (body, file, path = '', onProgress) => {
  const restrictionsPassed = checkForRestrictions(body, file, path);
  if (!restrictionsPassed) {
    return Promise.reject({
      message: 'File ' + file.name + ' too large. Please upload files up to 100MB.',
      userError: true
    });
  }
  let oldFileName = file.name.split('.');
  let fileExtension = oldFileName.pop();
  oldFileName = oldFileName.join('').replace(/[^a-zA-Z0-9.]/g, '-');
  // if you want to change the way used to generate the file name, take a look at the following function: getFileUrlName
  const newFileName = `${oldFileName}-${Date.now()}.${fileExtension}`;
  const fileKey = `${path}/${newFileName}`;
  const fileSize = (file.size / (1024 * 1024)).toFixed(2);
  const { url, checksum } = await getPresignedUrl(file, fileKey, fileSize);
  const { data } = url;
  const preSignedUrl = data && data[0]?.url;
  return uploadFileWithProgress({ file, preSignedUrl, checksum, onProgress, fileKey });
};

export const getS3Url = (name) => {
  if (!name) return;
  if (name.includes('http')) {
    return name;
  }
  return `${import.meta.env.REACT_APP_CLOUDFRONT_URL}/${name}`;
};

export const uploadBlobToAWS = async (file, path = '', onProgress) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = async () => {
      const buffer = new Uint8Array(fileReader.result).buffer;
      try {
        const response = await addBlobToS3Bucket(buffer, file, path, onProgress);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };
  });
};

const addFreeBlobToS3 = async (file, path, onProgress) => {
  const fileSize = (file.size / (1024 * 1024)).toFixed(2);
  const { url, checksum } = await getPresignedUrl(file, path, fileSize);
  const { data } = url;
  const preSignedUrl = data && data[0]?.url;
  if (!preSignedUrl) return Promise.reject('No preSignedUrl found');
  return uploadFileWithProgress({
    file,
    preSignedUrl,
    checksum,
    onProgress,
    fileKey: path,
    isURL: true
  });
};

export const uploadBlobToAWSWithFreeName = async (file, path = 'untitled', onProgress) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = async () => {
      try {
        const response = await addFreeBlobToS3(file, path, onProgress);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };
  });
};

export const deleteFromS3UsingPreSignedUrls = async (keys) => {
  if (!keys || keys.length === 0) {
    return;
  }
  const { data } = await store.dispatch(
    apiSlice.endpoints.createPreSignedUrl.initiate({
      keys: keys,
      operation: 'DELETE'
    })
  );

  const preSignedUrls = data.map((item) => item?.url);
  if (!preSignedUrls && preSignedUrls.some((url) => !url))
    throw new Error('No preSignedUrls found');
  const promises = preSignedUrls.map((url) => {
    return fetch(url, {
      method: 'DELETE'
    });
  });

  await Promise.all(promises);
  return true;
};

export const deleteFromS3 = async (keys) => {
  const isArray = Array.isArray(keys);
  const targetKeys = isArray ? keys : [keys];
  const { data } = await store.dispatch(
    apiSlice.endpoints.deleteByKeys.initiate({
      body: {
        keys: targetKeys
      }
    })
  );
  return data;
};
