import React, { useState } from 'react';
import styles from './styles.module.scss';
import ResourceCard from 'components/atoms/ResourceCard/ResourceCard';

export default function index({ currentMediaIds, course }) {
  const [showAll, setShowAll] = useState(false);

  const allLessons = course?.chapters?.map((chapter) => chapter.lessons)?.flat() || [];
  const allResources = allLessons.map((lesson) => lesson.resources)?.flat();
  const shownResources = showAll ? allResources : allResources.filter((resource) => resource.lessonId === currentMediaIds.uuid);


  return (
    <div className={styles.resources_tab_container}>
      <div className={styles.header_area}>
        <h2 className={styles.sectionTitle}>Resources ({shownResources.length})</h2>
        <div className={styles.control_toggle_container}>
          <button
            className={`${styles.control_toggle} ${showAll ? styles.active : ''}`}
            onClick={() => setShowAll(true)}
          >
            All resources
          </button>
          <button
            className={`${styles.control_toggle} ${!showAll ? styles.active : ''}`}
            onClick={() => setShowAll(false)}
          >
            Lesson based
          </button>
        </div>
      </div>
      <div className={styles.cardlist}>
        {shownResources?.map((resource, idx) => (
          <ResourceCard
            key={resource?.id || idx}
            resource={resource}
          />
        ))}
      </div>
    </div>
  );
}
