import React, { useState } from 'react';
import styles from './EmployeeCard.module.scss';
import Text from 'components/atoms/Text/Text';

import { ProfilePic } from 'components/ui';
import SideProfile from '../SideProfile/SideProfile';
import { Link } from 'react-router-dom';
import defaultProfileImage from '../../../assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
import { getWeekDay } from 'helpers/dateHandler';
import { isSharedMode } from 'helpers';
import { useGetUrlQuery } from 'hooks';
import { Button } from 'components/ui';
import { AVAILABILITY_OPTIONS } from 'constants';
import { useTranslation } from 'react-i18next';
// TODO: this component MUST be refactored and split into smaller components, .e.g.: EmployeeCard, EmployeeCardList, EmployeeCardGrid, EmployeeCardShared(maybe)
const EmployeeCard = ({
  clickEvent,
  isList,
  selectedEmployeesIds,
  id,
  disableCheckbox,
  showContactButton,
  showProfileButton,
  ...rest
}) => {
  const [modal, setModal] = useState(false);
  const slug = useGetUrlQuery('slug');
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${styles.employeeCard} ${styles[rest?.extraClass]}
        ${selectedEmployeesIds?.includes(id) ? styles.aftereffect : ``}
        `}
        onClick={() => (!isSharedMode() ? clickEvent?.(id) : null)}
        style={{
          backgroundImage: !isList
            ? `url(${rest?.picture ? getS3Url(rest?.picture) : defaultProfileImage})`
            : ``,
          height: rest?.height,
          width: rest?.width
        }}
      >
        {!disableCheckbox && !isSharedMode() && (
          <label className={styles.checkcontainer}>
            <input
              onChange={() => {}}
              type="checkbox"
              checked={selectedEmployeesIds?.includes(id)}
            />
            <span
              onClick={() => {
                clickEvent(id);
              }}
              className={styles.checkmark}
            />
          </label>
        )}
        <div className={isSharedMode() ? `${styles.flex} ${styles.sharedFlex}` : styles.flex}>
          {isList && (
            <ProfilePic image={rest?.picture ? getS3Url(rest?.picture) : defaultProfileImage} />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '15px 0',
              gap: '10px'
            }}
          >
            {!isList && rest.availability?.status === AVAILABILITY_OPTIONS.online.value && (
              <div className={styles['online-circle']} />
            )}
            <h4 style={{ color: '#cbcbcb' }}>
              {rest?.contact?.firstName || rest?.contact?.lastName
                ? `${rest.contact.firstName ?? ''} ${rest.contact.lastName ?? ''}`.trim()
                : rest?.contact?.email}
            </h4>
          </div>
        </div>
        {isList && (
          <Text
            extraClass="teamName"
            align="left"
            subheading={t('Team')}
            paragraph={rest?.team?.at(0)?.name}
          />
        )}
        {isList && (
          <Text
            align="left"
            subheading={t('Status')}
            extraClass="teamName"
            paragraph={rest.availability?.status || ''}
            subColor={rest.availability?.status === 'online' ? 'green' : '#a8a8a8'}
          />
        )}
        {isList && (
          <Text
            align="left"
            subheading={t('Availability')}
            extraClass="teamName"
            paragraph={
              rest.availability?.daysAvailability?.[getWeekDay()]?.startTime
                ? `${t('From')} ${rest.availability?.daysAvailability[getWeekDay()]?.startTime} ${t(
                    'To'
                  )} ${rest?.availability?.daysAvailability[getWeekDay()]?.endTime}`
                : t('Not set')
            }
          />
        )}
        <div
          className={
            isList
              ? ``
              : isSharedMode()
                ? `${styles.sharedButton} ${styles.buttons}`
                : styles.buttons
          }
        >
          {' '}
          {showProfileButton ? (
            <Link
              to={`/profile/${id}${!rest?.workingHoursVisible ? `` : `?sec=workingHours`}${
                isSharedMode() ? `?slug=${id}-${slug}` : ''
              }`}
              state={{ passKey: rest.passKey }}
            >
              <Button bgColor={isList ? '#262626' : '#404040'} color={isList ? '#a8a8a8' : 'white'}>
                {t('Profile')}
              </Button>
            </Link>
          ) : (
            <div className={styles.emptyBlock}></div>
          )}
          {showContactButton && !isList && !isSharedMode() && (
            <Button
              bgColor={isList ? '#262626' : '#2a2a2a'}
              onClick={(e) => {
                e.stopPropagation();
                setModal(true);
              }}
              color="white"
            >
              {t('Contact')}
            </Button>
          )}
        </div>
      </div>
      {modal && <div onClick={() => setModal(false)} className={styles.backdrop} />}
      {!isSharedMode() && (
        <SideProfile profileData={{ ...rest, id }} setModal={setModal} modal={modal} />
      )}
    </>
  );
};
export default EmployeeCard;
