import React from 'react';
import styles from './styles.module.scss';
import useMediaQuery from '../../hooks/useMediaQuery';

const ListView = ({ courses }) => {
    const isMax800 = useMediaQuery('(max-width: 800px)');

    const getButtonText = (course) => {
        if (course.completed) return 'Download certificate';
        if ((course.completionPercentage || 0) === 0) return 'View Course';
        return 'Continue learning';
    };

    return (
        <div className={styles.container}>
            <div className={styles.listContainer}>
                <div className={styles.header}>
                    <div className={styles.column}>Course title</div>
                    {!isMax800 && <div className={styles.column}>Chapters</div>}
                    <div className={styles.column}>Progress</div>
                    <div className={styles.column}></div>
                </div>
                {courses.map((course, index) => {
                    const buttonText = getButtonText(course);
                    return (
                        <div key={index} className={styles.row}>
                            <div className={styles.courseInfo}>
                                <div className={styles.courseTitle}>
                                    <img
                                        src={course.thumbnail}
                                        alt={course.title}
                                        className={styles.courseImage}
                                    />
                                    <div className={styles.courseDetails}>
                                        <span className={styles.courseName}>{course.title}</span>
                                        <span className={styles.author}>{course.author}</span>
                                    </div>
                                </div>
                            </div>

                            {!isMax800 && (
                                <div className={styles.progress}>
                                    <span className={styles.meta}>
                                        {course?.lessons?.length || 0} lessons • {course?.chapters?.length || 0} chapters
                                    </span>
                                </div>
                            )}

                            <div className={styles.combo}>
                                {(course.completionPercentage || 0) === 0 ? (
                                    <div className={styles.notStarted}>
                                        <span>Not Started</span>
                                    </div>
                                ) : (
                                    <div className={styles.progressBar}>
                                        <div
                                            className={styles.progressFill}
                                            style={{ width: `${(course.completionPercentage || 0)}%` }}
                                        ></div>
                                        <div className={styles.progressText}>
                                            <span>
                                                {course.completed
                                                    ? '100% completed'
                                                    : `${(course.completionPercentage || 0)}%`}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {isMax800 && (
                                    <div className={styles.progress}>
                                        <span className={styles.meta}>
                                            {course?.lessons?.length || 0} lessons • {course?.chapters?.length || 0} chapters
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className={styles.certificate}>
                                <button className={styles.certificateButton}>
                                    {buttonText}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListView;
