import styles from './AboutCourse.module.scss';
import placeholder from 'assets/images/wizzy.webp';

export default function AboutCourse({ thumbnail, description, skills }) {
  return (
    <div className={styles.container}>
      <img className={styles.image_thumb} src={thumbnail} />
      <div className={styles.content}>
        <h2 className={styles.title}>About this course</h2>
        <p className={styles.desc}>{description}</p>
        <h3 className={styles.subtitle}>Skills you'll gain:</h3>
        <ul className={styles.list}>
          {skills?.map((item, i) => {
            return (
              <li className={styles.listItem} key={i}>
                {item}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
