import React, { useEffect, useState } from 'react';
import styles from './TemplateTabs.module.scss';
import ArrowSliderI from 'assets/icons/arrowdown.svg?react';
import { PROFILE_SECTIONS } from 'constants';
import { getGlobalEditSelector, setGlobalEdit } from 'store/individualProfile.slice';
import { isSharedMode } from 'helpers';
import { useGetUrlQuery } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGetSharedProfile from '../../hooks/useGetSharedProfile';
import { TabsList } from '../../templates/utils';
import { useTranslation } from 'react-i18next';
function TemplateTabs({ companyVisibleTabs, templateTabs = [], isCurrentUserProfile }) {
  const sectionQuery = useGetUrlQuery('sec');
  const [activeSection, setActiveSection] = useState(sectionQuery || PROFILE_SECTIONS.home);
  const [scrollableTags, setScrollableTags] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalEdit = useSelector(getGlobalEditSelector);
  const [_, navigateWithSharedMode] = useGetSharedProfile();
  const { t } = useTranslation();

  const setSectionQueryParam = (section) => {
    if (isSharedMode()) {
      navigateWithSharedMode(`?sec=${section}`);
    } else {
      navigate(`?sec=${section}`);
    }
  };

  const slideHandler = (direction) => {
    const tabs = document.querySelector(`.${styles.tabs}`);
    if (direction === 1 && tabs.scrollLeft > 0) {
      tabs.scrollLeft -= 200;
    } else if (direction === 2 && tabs.scrollLeft < tabs.scrollWidth - tabs.offsetWidth) {
      tabs.scrollLeft += 200;
    }
  };
  let slideInterval;
  const startSlide = (direction) => {
    slideInterval = setInterval(() => {
      slideHandler(direction);
    }, 100);
  };

  const stopSlide = () => {
    clearInterval(slideInterval);
  };

  useEffect(() => {
    const checkScrollable = () => {
      const tabs = document.querySelector(`.${styles.tabs}`);
      if (tabs) {
        if (tabs.scrollWidth > tabs.offsetWidth) {
          setScrollableTags(true);
        } else {
          setScrollableTags(false);
        }
      }
    };

    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  useEffect(() => {
    setActiveSection(sectionQuery || PROFILE_SECTIONS.home);
  }, [sectionQuery]);
  return (
    // TODO: We should render the tabs based on the templateTabs array
    // ========================== to be worked on ==========================
    // ========================== to be worked on ==========================
    // ========================== to be worked on ==========================

    <div className={styles.tabsContainer}>
      {scrollableTags && (
        <button
          className={styles.upArrow}
          onMouseDown={() => startSlide(1)}
          name="arrow"
          onMouseUp={stopSlide}
        >
          <ArrowSliderI />
        </button>
      )}
      <div className={`${styles.tabs} joyrideTabs`}>
        <button
          onClick={() => {
            setGlobalEdit(false);
            setActiveSection(PROFILE_SECTIONS.home);
            setSectionQueryParam(PROFILE_SECTIONS.home);
          }}
          className={`${activeSection === PROFILE_SECTIONS.home ? styles.active : ``} joyridehome`}
        >
          {t('Home')}
        </button>
        {companyVisibleTabs?.role && templateTabs.includes(TabsList.role) && (
          <button
            onClick={() => {
              setGlobalEdit(false);
              setActiveSection(PROFILE_SECTIONS.role);
              setSectionQueryParam(PROFILE_SECTIONS.role);
            }}
            className={`${
              activeSection === PROFILE_SECTIONS.role ? styles.active : ``
            } joyriderole`}
          >
            {t('My Role')}
          </button>
        )}
        {companyVisibleTabs?.awards && templateTabs.includes(TabsList.awards) && (
          <button
            onClick={() => {
              setGlobalEdit(false);

              setActiveSection(PROFILE_SECTIONS.awards);
              setSectionQueryParam(PROFILE_SECTIONS.awards);
            }}
            className={`${
              activeSection === PROFILE_SECTIONS.awards ? styles.active : ``
            } joyrideawards`}
          >
            {t('Awards')}
          </button>
        )}
        {!isSharedMode() && (
          <>
            {' '}
            {companyVisibleTabs?.reviews && templateTabs.includes(TabsList.reviews) && (
              <button
                onClick={() => {
                  setGlobalEdit(false);

                  setActiveSection(PROFILE_SECTIONS.reviews);

                  setSectionQueryParam(PROFILE_SECTIONS.reviews);
                }}
                className={`${
                  activeSection === PROFILE_SECTIONS.reviews ? styles.active : ``
                } joyridereviews`}
              >
                {t('Reviews')}
              </button>
            )}
            {companyVisibleTabs?.workingHours && templateTabs.includes(TabsList.workingHours) && (
              <button
                onClick={() => {
                  setGlobalEdit(false);

                  setActiveSection(PROFILE_SECTIONS?.workingHours);

                  setSectionQueryParam(PROFILE_SECTIONS?.workingHours);
                }}
                className={`${
                  activeSection === PROFILE_SECTIONS?.workingHours ? styles.active : ``
                } joyrideavailability`}
              >
                {t('Working Hours')}
              </button>
            )}
            {companyVisibleTabs?.itEssentials && templateTabs.includes(TabsList.itEssentials) && (
              <button
                onClick={() => {
                  setGlobalEdit(false);

                  setActiveSection(PROFILE_SECTIONS.itEssentials);

                  setSectionQueryParam(PROFILE_SECTIONS.itEssentials);
                }}
                className={`${
                  activeSection === PROFILE_SECTIONS.itEssentials ? styles.active : ``
                } joyrideitEssentials`}
              >
                {t('IT Essentials')}
              </button>
            )}
          </>
        )}
      </div>
      {scrollableTags && (
        <button
          className={styles.downArrow}
          onMouseDown={() => startSlide(2)}
          onMouseUp={stopSlide}
        >
          <ArrowSliderI />
        </button>
      )}
    </div>
  );
}

export default TemplateTabs;
