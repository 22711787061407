import React from 'react';
import { useSelector } from 'react-redux';
import styles from './TestimonialsSection.module.scss';
import useTestimonialsUtils from 'components/organisms/Profile/hooks/templates/useTestimonialsUtils';
import { getCurrentUserIdSelector } from 'store/login.slice';
import { useGetReviewsByProfileIdQuery } from 'api/individual-profile';
import { Loader } from 'components/ui';
import { useHandleRtkPagination } from 'hooks';
import TestimonialCard from './TestimonialCard';
import { isSharedMode } from 'helpers';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";
const defaultSectionTitle = 'Testimonials';

function TestimonialsSectionView({ data: reviewsData, sectionTitle }) {
  const userId = useSelector(getCurrentUserIdSelector);
  const { t } = useTranslation();

  const { isCurrentUserProfile } = useTestimonialsUtils({ userId });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(2.5, reviewsData?.length),
    slidesToScroll: Math.min(2.5, reviewsData?.length),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, reviewsData?.length),
          slidesToScroll: Math.min(2, reviewsData?.length)
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(1.5, reviewsData?.length),
          slidesToScroll: Math.min(1.5, reviewsData?.length)
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: Math.min(1, reviewsData?.length),
          slidesToScroll: Math.min(1, reviewsData?.length)
        }
      }
    ]
  };
  
  return (
    <div className={styles.testimonialsContainer}>
      <div className={styles.testimonials}>
        <h3 className={styles.title} id="testimonials-title">
          {sectionTitle ?? t(defaultSectionTitle)}
        </h3>
        <div className={styles.testimonialWrapper}>
          <Slider {...settings} className="resourcesSlider">
            {reviewsData?.map((item) => (
              <TestimonialCard
                originReview={item}
                key={item.id}
                testimonial={item}
                isCurrentUserProfile={isCurrentUserProfile}
                {...item}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

function TestimonialsSection({ sectionTitle, profileId }) {
  const { result } = useHandleRtkPagination({
    queryName: 'getReviewsByProfileId',
    rtkQuery: useGetReviewsByProfileIdQuery,
    rtkQueryArgs: { profileId },
    rtkQueryOptions: {
      skip: !profileId || isSharedMode()
    }
  });
  const { t } = useTranslation();

  const placeHolder = [
    {
      id: 1,
      description: t('placeholder-working-with-this-individual-has-been-a-game-changer'),
      firstName: 'Jane',
      position: t('Developer')
    },
    {
      id: 2,
      description: t("placeholder-their-attention-to-detail"),
      firstName: 'Sarah',
      position: t('Project Manager')
    },
    {
      id: 3,
      description: t("placeholder-this-persons-dedication"),
      firstName: 'Sam',
      position: t('Lead Designer')
    }
  ];

  const { data, isFetching, isError, error } = result;
  if (isFetching) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const reviews = data?.result;
  return (
    <TestimonialsSectionView
      data={reviews && reviews?.length !== 0 ? reviews : placeHolder}
      sectionTitle={sectionTitle}
    />
  );
}

export default TestimonialsSection;
