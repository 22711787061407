import React from 'react';
import { useState } from 'react';
import styles from './RatingStarsHoverable.module.scss';
function RatingStarsHoverable({ size, value, setValue, havorable = true }) {
  const [previewValue, setPreviewValue] = useState(-1);
  const [previewActive, setPreviewActive] = useState(false);
  const stars = [];
  for (let i = 0; i < 5; i++) {
    const valueToCheckAgainst = previewActive ? previewValue : value;
    if (i < valueToCheckAgainst) {
      stars.push(
        <span
          key={i}
          style={{
            fontSize: size * 10 + 'px'
          }}
          className={`${styles.star} ${styles.active}`}
          onClick={() => {
            havorable && setValue(i + 1);
          }}
          onMouseEnter={() => {
            havorable && previewActive && setPreviewValue(i + 1);
          }}
        >
          &#9733;
        </span>
      );
    } else {
      stars.push(
        <span
          style={{
            fontSize: size * 10 + 'px'
          }}
          key={i}
          className={styles.star}
          onClick={() => {
            havorable && setValue(i + 1);
          }}
          onMouseEnter={() => {
            havorable && previewActive && setPreviewValue(i + 1);
          }}
        >
          &#9734;
        </span>
      );
    }
  }
  return (
    <div
      className={styles.stars}
      onMouseEnter={() => {
        havorable && setPreviewActive(true);
      }}
      onMouseLeave={() => {
        havorable && setPreviewActive(false);
      }}
    >
      {stars}
    </div>
  );
}

export default RatingStarsHoverable;
