import { useEffect, useLayoutEffect, useState } from 'react';
import { getS3Url, uploadBlobToAWSWithFreeName } from 'helpers';
import { updateLoader } from 'helpers/general';
import { useDispatch } from 'react-redux';
import { getCourse, getDraftById } from 'api/courses/coursesApis';
import { togglePopup } from 'store/popups/popupSlice';
import { useNavigate } from 'react-router-dom';
import { updateCourse } from 'store/course.slice';

const useCourseHook = ({ courseId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
    const [headerData, setHeaderData] = useState({
        title: '',
        subtitle: '',
        skills: [],
    });
    const [headerBanners, setHeaderBanners] = useState({
        banner: '',
        thumbnail: '',
    });
    const [chapters, setChapters] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [courseMaterial, setCourseMaterial] = useState([]);
    const [canSave, setCanSave] = useState(true);

    const getCoursedata = async (id) => {
        const response = await getCourse(id);
        return response;
    }

    const courseStructuredState = {
        ...headerData,
        banner: headerBanners.banner || '',
        thumbnail: headerBanners.thumbnail || '',
        chapters: [...chapters].map((chapter) => {
            return {
                ...chapter,
                lessons: [...lessons].filter((lesson) => lesson.chapterId === chapter.id).map((lesson) => {
                    return {
                        ...lesson,
                        resources: [...courseMaterial].filter((content) => content.lessonId === lesson.id || content.lessonId === lesson.uuid),
                    }
                })
            }
        }),
        id: courseId
    }

    useLayoutEffect(() => {
        dispatch(
            togglePopup({
                pointer: 1,
                data: {
                    desc: 'Fetching course',
                    noVoidExit: true,
                }
            })
        );
        getCoursedata(courseId).then(res => {
            const data = res.result || {};
            if (data) {
                const { title, subtitle, skills } = data || {};
                const incomingChapters = data?.chapters || [];
                const incomingLessons = incomingChapters.map((chapter) => chapter?.lessons || []).flat() || [];
                const allResources = incomingLessons.map((lesson) => lesson?.resources || []).flat() || [];
                setHeaderData({
                    title: title || '',
                    subtitle: subtitle || '',
                    skills: skills || [],
                });
                setChapters(incomingChapters);
                setLessons(incomingLessons);
                setCourseMaterial(allResources || []);
                setHeaderBanners({
                    banner: data?.banner || '',
                    thumbnail: data?.thumbnail || '',
                });
                dispatch(
                    togglePopup({
                        pointer: 0,
                        data: null
                    })
                );
            }
            else {
                throw new Error('Draft was not found');
            }
        }).catch((e) => {
            dispatch(
                togglePopup({
                    pointer: 404,
                    data: {
                        desc: e.message,
                        validButtonText: 'Navigate to dashboard',
                        validAction: () => navigate('/dashboard/courses'),
                        noVoidExit: true,
                    }
                })
            );
        })
    }, []);

    useEffect(() => {
        const someAreUploading = courseMaterial.some((content) => content?.isLoading) || lessons.some((lesson) => lesson?.content?.isLoading);
        if (someAreUploading) {
            setCanSave(false);
        }
        else {
            setCanSave(true);
        }
    }, [courseMaterial, lessons, canSave]);

    const changeHeader = (payload) => {
        setHeaderData((prevData) => ({ ...prevData, ...payload }));
    };
    const changebanner = (payload) => {
        setHeaderBanners((prevData) => ({ ...prevData, ...payload }));
    }

    const handleBannerUpload = (file, id) => {
        if (!file) {
            return;
        }
        if (!allowedTypes.includes(file?.type)) {
            return;
        }
        if (file) {
            setCanSave(false);
            updateLoader({
                loaded: 10,
                total: 100,
            }, id) // fake instant loader
            const name = `courses/${courseId || 'undefined'}/banner.${file?.name?.split('.')?.pop() || 'png'}`;
            uploadBlobToAWSWithFreeName(file, name, (value) => updateLoader(value, id)).then((url) => {
                changebanner({ banner: getS3Url(name) });
            }).finally(() => {
                setCanSave(true);
            });
        }
    };

    const handleContentImgUpload = (file) => {
        if (!allowedTypes.includes(file?.type)) {
            return;
        }
        if (file) {
            updateLoader({
                loaded: 10,
                total: 100,
            }, 'content-img-loader')
            setCanSave(false);
            const name = `courses/${courseId || 'undefined'}/content-img.${file?.name?.split('.')?.pop() || 'png'}`;
            uploadBlobToAWSWithFreeName(file, name, (value) => updateLoader(value, 'content-img-loader')).then((url) => {
                changebanner({ thumbnail: getS3Url(name) });
            }).finally(() => {
                setCanSave(true);
            });
        }
    }

    const handleCoverDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        if (!file) {
            return;
        }
        if (!allowedTypes.includes(file?.type)) {
            return;
        }
        handleBannerUpload(file, 'banner-loader');
    }

    const handleContentDelete = (contents) => {
        setCourseMaterial((prev) => prev.filter((content) => !contents.find(
            c => c.id === content.id
        )));
        // delete elements from contents
    }

    const deleteLessons = (lessonsArr) => {
        setLessons((prev) => prev.filter((lesson) => !lessonsArr.find(
            l => l.uuid === lesson.uuid
        )));
        const foundContent = courseMaterial.filter((content) => lessonsArr.find(
            c => c.id === content.lessonId
        ));
        if (foundContent) {
            handleContentDelete(foundContent);
        }
    };

    const deleteChapter = (e, chapteruuid) => {
        e.stopPropagation();
        setChapters((prev) => prev.filter((chapter) => chapter.uuid !== chapteruuid));
        const foundLessons = lessons.filter((lesson) => lesson.chapteruuid === chapteruuid);
        if (foundLessons) {
            deleteLessons(foundLessons);
        }
    };

    const updateCourseFn = () => {
        if (!canSave || !courseId) {
            return;
        }
        dispatch(updateCourse({
            data: courseStructuredState,
            callback: null
        }))
    }

    const handleCtrlSave = (e) => {
        const keyInputLowered = e?.key?.toLowerCase();
        const isCtrl = e.ctrlKey || e.metaKey;
        if (keyInputLowered && isCtrl && keyInputLowered === 's') {
            e.preventDefault();
            updateCourseFn();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleCtrlSave);
        return () => {
            document.removeEventListener('keydown', handleCtrlSave);
        }
    }, [canSave, headerData, chapters, lessons, courseMaterial, headerBanners, courseStructuredState]);

    return {
        headerData, headerBanners, chapters, setChapters,
        lessons, setLessons, courseMaterial, setCourseMaterial, changeHeader, handleBannerUpload,
        handleContentImgUpload, handleCoverDrop, handleContentDelete, deleteLessons, deleteChapter,
        canSave, setCanSave, updateCourseFn
    }
};


export default useCourseHook;
