import { axiosInstance } from 'api/apiSlice';

const mainUrl = '/v1/courses';

export async function createCourseDraft() {
  const response = await axiosInstance.post(`${mainUrl}/draft`);
  return response.data;
}

export async function getDrafts() {
  const response = await axiosInstance.get(`${mainUrl}/draft`);
  return response.data;
}

export async function getDraftById(id) {
  const response = await axiosInstance.get(`${mainUrl}/${id}/draft`);
  return response.data;
}

export async function updateDraftById(id, data) {
  const response = await axiosInstance.patch(`${mainUrl}/${id}/draft`, data);
  return response.data;
}

export async function createCourse(data) {
  const response = await axiosInstance.post(`${mainUrl}`, data);
  return response.data;
}

export async function getCourses() {
  const response = await axiosInstance.get(`${mainUrl}`);
  return response.data;
}

export async function getCourse(courseId) {
  const response = await axiosInstance.get(`${mainUrl}/${courseId}`);
  return response.data;
}

export async function updateCourseAPI(data) {
  const { id, _id } = data || {};
  const response = await axiosInstance.patch(`${mainUrl}/${id || _id}`, data);
  return response.data;
}

export async function deleteCourseAPI(id) {
  const response = await axiosInstance.delete(`${mainUrl}/${id}`);
  return response.data;
}

export async function toggleLessonProgressAPI(courseId, chapterId, lessonId) {
  if (!courseId || !chapterId || !lessonId) {
    throw new Error('courseId, chapterId and lessonId are required');
  }
  const response = await axiosInstance.post(
    `${mainUrl}/${courseId}/${chapterId}/${lessonId}/progress`
  );
  return response?.data;
}

export async function addReviewToCourseAPI(courseId, review) {
  if (!courseId || !review) {
    throw new Error('courseId and review are required');
  }
  const response = await axiosInstance.post(`${mainUrl}/${courseId}/reviews`, review);
  return response.data;
}

export async function updateCourseReviewAPI(review) {
  const id = review?.id || review?._id;
  if (!id || !review) {
    throw new Error('courseId and review are required');
  }
  const response = await axiosInstance.patch(`${mainUrl}/reviews/${id}/`, review);
  return response.data;
}

export async function deleteCourseReviewAPI(reviewId) {
  if (!reviewId) {
    throw new Error('reviewId is required');
  }
  const response = await axiosInstance.delete(`${mainUrl}/reviews/${reviewId}`);
  return response.data;
}
