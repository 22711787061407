export const fetchOneCourseHelper = (state, course) => {
  const prevCourses = state.courses || [];
  if (!prevCourses.find((prevCourse) => prevCourse.id === course.id)) {
    state.courses = [...prevCourses, course];
  } else {
    state.courses = prevCourses.map((prevCourse) => {
      if (prevCourse.id === course.id) {
        return course;
      }
      return prevCourse;
    });
  }
  state.fetching = false;
};

export const toggleProgressHelper = (state, progressData) => {
  const { courseId } = progressData || {};
  const prevCourse = state.courses.find((course) => course.id === courseId);
  if (!prevCourse) {
    return;
  }
  const prevProgressArray = prevCourse?.progress || [];
  const prevProgress = prevProgressArray.find(
    (progress) =>
      progress.studentId === progressData.studentId && progress.chapterId === progressData.chapterId
  );
  const newProgressData = prevProgress
    ? prevProgressArray.map((progress) => {
        if (
          progress.studentId === progressData.studentId &&
          progress.chapterId === progressData.chapterId
        ) {
          return progressData;
        }
        return progress;
      })
    : [...prevProgressArray, progressData];
  const updatedCourse = {
    ...prevCourse,
    progress: newProgressData
  };
  state.courses = state.courses.map((course) => {
    if (course.id === courseId) {
      return updatedCourse;
    }
    return course;
  });
  state.fetching = false;
};

export const addReviewToCourseHelper = (state, newReview) => {
  const { courseId } = newReview || {};
  const prevCourses = state.courses || [];
  const prevCourse = prevCourses.find((course) => course.id === courseId);
  if (!prevCourse) {
    return;
  }
  const prevReviews = prevCourse?.reviews || [];
  const newCourse = {
    ...prevCourse,
    reviews: [newReview, ...prevReviews]
  };
  state.courses = prevCourses.map((course) => {
    if (course.id === courseId || course._id === courseId) {
      return newCourse;
    }
    return course;
  });
};

export const editCourseReviewHelper = (state, editedReview) => {
  const { courseId } = editedReview || {};
  const prevCourses = state.courses || [];
  const prevCourse = prevCourses.find((course) => course.id === courseId);
  if (!prevCourse) {
    return;
  }
  const prevReviews = prevCourse?.reviews || [];
  const newCourse = {
    ...prevCourse,
    reviews: prevReviews.map((review) => {
      const prevId = review.id || review._id;
      const newId = editedReview.id || editedReview._id;
      if (prevId === newId) {
        return editedReview;
      }
      return review;
    })
  };
  state.courses = prevCourses.map((course) => {
    if (course.id === courseId || course._id === courseId) {
      return newCourse;
    }
    return course;
  });
};

export const deleteCourseHelper = (state, payload) => {
  const { courseId } = payload;
  const reviewId = payload.id || payload._id;
  const prevCourses = state.courses || [];
  const prevCourse = prevCourses.find(
    (course) => course.id === courseId || course._id === courseId
  );
  if (!prevCourse) {
    return;
  }
  const prevReviews = prevCourse?.reviews || [];
  const newCourse = {
    ...prevCourse,
    reviews: prevReviews.filter((review) => {
      const prevId = review.id || review._id;
      return prevId !== reviewId;
    })
  };
  state.courses = prevCourses.map((course) => {
    if (course.id === prevCourse.id) {
      return newCourse;
    }
    return course;
  });
};
