/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'

const index = ({ data, close }) => {
  const { header, desc, validAction, validButtonText } = data
  return (
    <div className={styles.popup_wrapper}>
      <div className={styles.content_wrapper}>
        {
          header && <h2 className={styles.loading_text}>{header}</h2>
        }
        <div className={styles.desc_container}>
          <p className={styles.loading_desc}>{desc}</p>
        </div>
        <div className={styles.button_container}>
          <button className={`${styles.valid_button} ${styles.button}`} onClick={close}>Cancel</button>
          <button className={`${styles.cancel_button} ${styles.button}`} onClick={() => validAction()}>{validButtonText}</button>
        </div>
      </div>
    </div>
  )
}
export default index