import { Helmet } from 'react-helmet';
import EditCourses from 'components/organisms/NewCourse/EditCourse';

const EditCourse = () => {
    return (
        <>
            <Helmet>
                <title>Edit Course</title>
                <meta name="description" content="Edit course" />
            </Helmet>
            <EditCourses />
        </>
    )
}

export default EditCourse;
