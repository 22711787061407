import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { FaListUl, FaThLarge } from 'react-icons/fa';
import { setDisplayView } from 'store/course.slice';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearch } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';
import { useCourseSearch } from '../../hooks/useCourseSearch';
import DisplayIcon from '../../../../atoms/DisplayIcon';

export default function SearchContainer({
  links,
}) {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { displayView } = useSelector(state => state.courses);
  const { searchText, handleSearchChange, clearSearch } = useCourseSearch();

  const setListView = () => {
    dispatch(setDisplayView('list'));
  };

  const setGridView = () => {
    dispatch(setDisplayView('grid'));
  };

  return (
    <div className={styles.container}>
      <div className={styles.search_input_container}>
        <h1 className={styles.header}>My Dashboard</h1>

        <div className={styles.search_wrapper}>
          <input
            placeholder='Search all'
            value={searchText}
            onChange={handleSearchChange}
          />
          {searchText ? (
            <button onClick={clearSearch}><MdClear color='#fff' size={24} /></button>
          ) : (
            <button><BiSearch color='#fff' size={24} /></button>
          )}
        </div>

      </div>
      <div className={styles.nav_links}>
        <ul className={styles.nav_links_list}>
          {links?.map((link) => (
            <li key={link.value} className={styles.nav_links_item}>
              <Link
                to={link.path}
                className={`${styles.nav__link} ${pathname === link?.path ? styles.active_link : ''
                  }`}
              >
                {link.value}
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.display_btn_group}>
          <DisplayIcon Icon={FaThLarge} isActive={displayView === 'grid'} onClick={setGridView} />
          <DisplayIcon Icon={FaListUl} isActive={displayView === 'list'} onClick={setListView} />
        </div>
      </div>
    </div>
  );
}
