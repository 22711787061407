/* eslint-disable react/prop-types */
import React from 'react';
import Container from './Container/Container';

export const Filter = ({
  data,
  extraClass,
  onChangeData,
  onSearch,
  camelizedValues,
  currentState,
  filterOn,
  withAssetBtn
}) => {
  return (
    <Container
      {...{
        data,
        extraClass,
        currentState,
        withAssetBtn,
        onChangeData,
        onSearch,
        filterOn,
        camelizedValues
      }}
    />
  );
};