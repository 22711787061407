import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createCourse,
  getCourse,
  getCourses,
  updateCourseAPI,
  deleteCourseAPI,
  toggleLessonProgressAPI,
  addReviewToCourseAPI,
  updateCourseReviewAPI,
  deleteCourseReviewAPI
} from 'api/courses/coursesApis';
import { togglePopup } from './popups/popupSlice';
import {
  addReviewToCourseHelper,
  deleteCourseHelper,
  editCourseReviewHelper,
  fetchOneCourseHelper,
  toggleProgressHelper
} from './course-helpers';

const initialState = {
  courses: [],
  fetching: false,
  displayView: 'grid'
};

export const createNewCourse = createAsyncThunk(
  'draft-courses/createNewCourse',
  async ({ data, callback }, { dispatch }) => {
    const response = await createCourse(data);
    dispatch(
      togglePopup({
        pointer: 0,
        text: ''
      })
    );
    const { result } = response;
    if (!result) {
      throw new Error('Draft creation failed');
    }
    callback && callback(response);
    return result;
  }
);

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async (_, { dispatch }) => {
  const response = await getCourses();
  const { result } = response;
  if (!result) {
    throw new Error('Failed to fetch courses');
  }
  return result;
});

export const updateCourse = createAsyncThunk(
  'courses/updateCourse',
  async ({ data, callback }, { dispatch }) => {
    const response = await updateCourseAPI(data);
    const { result } = response;
    if (!result) {
      throw new Error('Draft creation failed');
    }
    callback && callback(response);
    return result;
  }
);

export const deleteCourse = createAsyncThunk(
  'courses/deleteCourse',
  async ({ id, callback }, { dispatch }) => {
    const response = await deleteCourseAPI(id);
    const { result } = response;
    if (!result) {
      throw new Error('Failed to fetch course');
    }
    callback && callback(response);
    return result || { id };
  }
);

export const fetchOneCourse = createAsyncThunk(
  'courses/fetchOneCourse',
  async ({ id, callback }, { dispatch }) => {
    const response = await getCourse(id);
    const { result } = response;
    if (!result) {
      throw new Error('Failed to fetch course');
    }
    callback && callback(response);
    return result;
  }
);

export const toggleLessonProgress = createAsyncThunk(
  'courses/toggleLessonProgress',
  async ({ courseId, chapterId, lessonId, callback }, { dispatch }) => {
    const response = await toggleLessonProgressAPI(courseId, chapterId, lessonId);
    const { studentId } = response;
    if (!studentId) {
      throw new Error('Failed to toggle lesson progress');
    }
    callback && callback();
    return response;
  }
);

export const addReviewToCourse = createAsyncThunk(
  'courses/addReviewToCourse',
  async ({ courseId, review, callback }, { dispatch }) => {
    const response = await addReviewToCourseAPI(courseId, review);
    callback && callback(response);
    return response;
  }
);

export const editCourseReview = createAsyncThunk(
  'courses/editCourseReview',
  async ({ review, callback }, { dispatch }) => {
    const response = await updateCourseReviewAPI(review);
    callback && callback(response);
    return response;
  }
);

export const rmcourseReview = createAsyncThunk(
  'courses/rmcourseReview',
  async ({ reviewId, callback }, { dispatch }) => {
    const response = await deleteCourseReviewAPI(reviewId);
    callback && callback(response);
    return response;
  }
);

const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setDisplayView(state, action) {
      state.displayView = action.payload;
    }
  },
  extraReducers: (builder) => {
    // creators
    builder.addCase(createNewCourse.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createNewCourse.fulfilled, (state, action) => {
      state.courses = [...state.courses, action.payload];
      state.fetching = false;
    });
    builder.addCase(createNewCourse.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });
    // fetchers
    builder.addCase(fetchCourses.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      state.courses = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchCourses.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });

    builder.addCase(fetchOneCourse.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchOneCourse.fulfilled, (state, action) => {
      fetchOneCourseHelper(state || [], action.payload);
    });
    builder.addCase(fetchOneCourse.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });
    // updators
    builder.addCase(updateCourse.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateCourse.fulfilled, (state, action) => {
      state.courses = state.courses?.map((course) => {
        if (course.id === action.payload.id) {
          return action.payload;
        }
        return course;
      });
      state.fetching = false;
    });
    builder.addCase(updateCourse.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });
    // deleters
    builder.addCase(deleteCourse.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      state.courses = state.courses?.filter((course) => course.id !== action.payload.id);
      state.fetching = false;
    });
    builder.addCase(deleteCourse.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });
    // progress toggler
    builder.addCase(toggleLessonProgress.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(toggleLessonProgress.fulfilled, (state, action) => {
      toggleProgressHelper(state, action.payload);
    });
    builder.addCase(toggleLessonProgress.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });

    // reviews crud
    builder.addCase(addReviewToCourse.fulfilled, (state, action) => {
      addReviewToCourseHelper(state, action.payload);
    });
    builder.addCase(editCourseReview.fulfilled, (state, action) => {
      editCourseReviewHelper(state, action.payload);
    });
    builder.addCase(rmcourseReview.fulfilled, (state, action) => {
      deleteCourseHelper(state, action.payload);
    });
  }
});

export const { setDisplayView } = courseSlice.actions;
export default courseSlice.reducer;
