import React, { useLayoutEffect } from 'react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import styles from './CoursesSection.module.scss';
import { coursesData } from 'constants/courses';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourse, fetchCourses } from 'store/course.slice';
// import SearchContainer from '../UpDashboard/components/SearchContainer';
import { Filter } from '../Filter';
import { useSearchHook } from '../UpDashboard/hooks/useSearchHook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createDraft } from 'store/coursesDraft.slice';
import { getCurrentUserProfileId } from 'store/login.slice';
import { togglePopup } from 'store/popups/popupSlice';

export default function CoursesSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courses } = useSelector((state) => state.courses);
  const currentUserProfileId = useSelector(getCurrentUserProfileId);

  // replace courses above with the used data

  const [searchParams] = useSearchParams();
  const { filterSelection, onChangeData, setFilterSelection, getMenu } = useSearchHook();
  const menu = getMenu();

  useLayoutEffect(() => {
    const newFilterSection = {};
    for (let entry of searchParams.entries()) {
      const key = entry[0];
      const value = entry[1].split(',');
      if (menu?.find((item) => item?.dbTitle === key) || key === 'name') {
        newFilterSection[key] = value;
      }
    }
    setFilterSelection(newFilterSection);
  }, [window.location.search]);

  const onSubmit = (possibledata) => {
    const dataToUse = possibledata || filterSelection;
    const updatedSearchParams = new URLSearchParams();
    Object.keys(dataToUse).forEach((key) => {
      if (dataToUse[key]?.length) {
        if (key === 'name' && dataToUse[key][0] === '') {
          return;
        }
        updatedSearchParams.set(key, dataToUse[key].join(',').toLowerCase());
      }
    });
    const sortedSearchParams = new URLSearchParams(
      [...updatedSearchParams.entries()].sort(([a], [b]) => {
        if (a === 'p') return 1;
        if (b === 'p') return -1;
        return 0;
      })
    );
    const newUrl = '/courses?' + sortedSearchParams.toString().replace(/%2C/g, ',');
    navigate(newUrl); // Optional: Use if you want to navigate to a specific URL
  };

  useLayoutEffect(() => {
    dispatch(fetchCourses());
  }, []);

  const filteredCourses = courses.filter((course) => {
    for (let key in filterSelection) {
      if (filterSelection[key].length) {
        if (key === 'name') {
          if (!course.title.toLowerCase().includes(filterSelection[key][0].toLowerCase())) {
            return false;
          }
        } else if (key === 'difficulty') {
          if (!filterSelection[key].includes(course[key])) {
            return false;
          }
        } else if (key === 'tags') {
          if (!filterSelection[key].every((tag) => course[key].includes(tag))) {
            return false;
          }
        } else if (key === 'duration') {
          if (course[key] < filterSelection[key][0] || course[key] > filterSelection[key][1]) {
            return false;
          }
        } else if (key === 'rating') {
          if (course[key] < filterSelection[key][0] || course[key] > filterSelection[key][1]) {
            return false;
          }
        }
      }
    }
    return true;
  });

  const createCourse = () => {
    dispatch(createDraft({
      data: null,
      callback: (response) => {
        const { result } = response;
        if (result && result?.id) {
          navigate(`/courses/drafts/${result.id}`);
        }
      }
    }));
  };

  const verifyEditData = ({ createdBy }) => {
    if (createdBy === currentUserProfileId) {
      return true;
    }
    return false;
  }

  const deleteCourseFn = (id) => {
    dispatch(togglePopup({
      pointer: 399,
      data: {
        validAction: () => {
          dispatch(deleteCourse({
            id,
            callback: dispatch(togglePopup({
              pointer: 0,
            })) // for now
           }));
        },
        desc: 'Are you sure you want to delete this course?',
        validButtonText: 'Delete',
      }
    }))
  };

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <h1 className={styles.title}>All Courses</h1>
        <div className={styles.search_container}>
          <Filter
            data={menu}
            onChangeData={onChangeData}
            onSearch={onSubmit}
            currentState={filterSelection}
            camelizedValues={true}
          />
        </div>
        <div className={styles.search_button_container}>
          <button className={styles.button} onClick={createCourse}>Add a new course</button>
        </div>
      </div>
      <div className={styles.courses}>
        {filteredCourses?.map((d, i) => {
          const { createdBy } = d;
          const canEdit = verifyEditData({ createdBy });
          return <CourseCard {...d} key={i} interactions={
            !canEdit ? null : {
              edit: () => {
                navigate(`/courses/edit/${d.id}`);
              },
              delete: () => {
                deleteCourseFn(d.id);
              }
            }
          } />;
        })}
      </div>
    </div>
  );
}
