import React, { useRef, useState } from 'react';
import styles from './ResultSharePostModal.module.scss';
import { Input } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import Copy from 'assets/icons/copy.svg?react';
import { fallbackCopyTextToClipboard } from 'helpers/general';
import { useTranslation } from 'react-i18next';
const ResultSharePostModal = ({ open, value, onClose }) => {
  const inputRef = useRef(null);
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { t } = useTranslation();

  const handleCopyClick = async () => {
    if (inputRef.current) {
      const textToCopy = inputRef.current.value;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textToCopy);
        setLinkCopied(true);
      } else {
        fallbackCopyTextToClipboard(textToCopy, () => {
          setLinkCopied(true);
        });
      }
    }
  };

  if (!open) return null;

  return (
    <CreateModal handleClose={onClose} textCancel="Close" showSave={false}>
      <div className={styles.shareLinkModal}>
        <h1>Share Post</h1>
        <Input
          label={t("sharing-link")}
          disabled
          type="text"
          extraClass="teamInput"
          value={value}
          name="passKey"
          onChange={(e) => {}}
          icon={<Copy />}
          inputRef={inputRef}
          clickEvent={handleCopyClick}
          tooltip={isLinkCopied ? `${t("link-copied")}` : `${t("copy-link")}`}
        />
       
      </div>
    </CreateModal>
  );
};

export default ResultSharePostModal;
