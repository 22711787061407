import React, { useState } from 'react';
import styles from './MediaItem.module.scss';
import Play from 'assets/icons/video.svg?react';
import File from 'assets/icons/document.svg?react';
import CheckInput from '../CheckInput/CheckInput';

export default function MediaItem({
  active,
  onClick,
  isChecked,
  onCheckChange,
  chapterId,
  id,
  title,
  content
}) {
  const { source, size, mimeType, lastModified, extension, duration } = content;

  const [isChecking, setIsChecking] = useState(false);

  const durationCalculator = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration - minutes * 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  const handleChecking = (e) => {
    e.stopPropagation();
    if (isChecking) return;
    setIsChecking(true);
    onCheckChange(chapterId, id, () => setIsChecking(false));
  };

  return (
    <div
      onClick={onClick}
      aria-hidden="true"
      className={active ? `${styles.item} ${styles.active}` : styles.item}
    >
      <CheckInput
        id={`checkbox-${chapterId}-${id}`}
        name={`mediaItem-${title}`}
        checked={isChecked}
        onChange={handleChecking}
        disabled={isChecking}
        extraClass="courseCheck"
      />

      <div className={styles.title}>
        <h3>{title}</h3>
        <div className={styles.bottom}>
          {mimeType.includes('video') ? <Play /> : <File />}
          <p>{durationCalculator(duration)}</p>
        </div>
      </div>
    </div>
  );
}
