import React, { useLayoutEffect, useState } from 'react';
import styles from './DashboardView.module.scss';
import SearchContainer from './components/SimpleSearchContainer';
import { Navigate } from 'react-router-dom';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from 'store/course.slice';
import { Route, Routes } from 'react-router-dom';
import CourseList from './components/CourseList';
import { getCurrentUserProfileId } from 'store/login.slice';

function DashboardView() {
  const dispatch = useDispatch();
  const { courses, fetching } = useSelector((state) => state.courses);
  const usedCourses = courses || [];
  const profileId = useSelector(getCurrentUserProfileId);
  const coursesWithPersonalProgress = usedCourses.filter((course) => {
    return course.progress.find((el) => el.studentId === profileId);
  }).map((course) => {
    const personalProgress = course?.progress?.filter((el) => el.studentId === profileId);
    const completedLessons = [].concat(...personalProgress?.map((el) => el.completedLessons));
    const percentage = Math.floor((completedLessons?.length / course?.lessons?.length) * 100) || 0;
    return {
      ...course,
      personalProgress,
      completedLessons,
      completionPercentage: percentage,
      completed: percentage >= 100
    };
  });

  const onGoingCourses = coursesWithPersonalProgress?.filter((course) => {
    return course?.completionPercentage < 100;
  });

  const completedCourses = coursesWithPersonalProgress?.filter((course) => {
    return course?.completed;
  });

  const links = [
    {
      value: 'Ongoing Courses',
      path: '/dashboard/courses/ongoing',
      link: 'courses/ongoing',
      page: <CourseList courses={onGoingCourses} />
    },
    {
      value: 'Completed Courses',
      path: '/dashboard/courses/completed',
      link: 'courses/completed',
      page: <CourseList courses={completedCourses} />
    },
    {
      value: 'Favorited Courses',
      path: '/dashboard/courses/favorited',
      link: 'courses/favorited',
      page: <CourseList courses={usedCourses} />
    }
  ];

  useLayoutEffect(() => {
    dispatch(fetchCourses());
  }, []);

  return (
    <ResponsiveWrapper>
      <div className={styles['main-wrapper']}>
        <div className={styles.search_container}>
          <SearchContainer links={links} />
        </div>
        <main className={styles.view_container}>
          <Routes>
            {
              links.map((datalink) => {
                return <Route exact={true} key={datalink.value} path={datalink.link} element={datalink.page} />
              })
            }
            <Route path='*' element={<Navigate to="courses/ongoing" />} />
          </Routes>
        </main>
      </div>
    </ResponsiveWrapper>
  );
}

export default DashboardView;
